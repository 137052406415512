import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { Portal } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  errorBox: {
    backgroundColor: "rgba(0, 102, 255, 1)",
  },
}));

const ErrorMessage = ({ message, statusCode }) => {
  const [open, setOpen] = useState(!!message && statusCode !== 401);
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  if (statusCode === 401) {
    window.location.reload();
  }
  return (
    <div className={classes.root} style={{ zindex: 2500 }}>
      <Portal>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            className={classes.errorBox}
            onClose={handleClose}
            severity="info"
          >
            {message}
          </Alert>
        </Snackbar>
      </Portal>
    </div>
  );
};

export default ErrorMessage;
