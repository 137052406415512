/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import "./userProfile.scss";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorMessage from "../ErrorMessage";
import Avatar from "@material-ui/core/Avatar";
import { getInitials, getStoreChecker } from "../../utils/utils";
import { DATA_FETCH_ERROR } from "../../common/constants";
import { Divider } from "@material-ui/core";
import UserImpersonation from "../UserImpersonation";
import impIcon from "../../images/impersonation_icon.png";
import { GET_DEFAULT_LOCATION } from "../../graphql/queries.js";
import { useLazyQuery } from "@apollo/client";
const styles = (theme) => ({
  root: {
    margin: 0,
    width: "100%",
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    left: theme.spacing(0.1),
    top: theme.spacing(-0.5),
    color: "#595959",
    flexdirection: "column",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children,
    classes,
    onClose,
    openImpMenu,
    setOpenImpMenu,
    setIsOpen,
    userProfileinfo,
    ...other
  } = props;

  const userEmail = userProfileinfo?.profileInfo?.data?.adUpn;
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);

  const handleImpClick = (e) => {
    setOpenImpMenu(true);
    setIsOpen(false);
    setTimeout(function Clickfunctionact() {
      window.newrelic.addPageAction("Impersonation", { result: "success" });
    }, 1000);
  };

  const [
    getDefaultLocation,
    { error: locationError = {}, loading: locationLoading },
  ] = useLazyQuery(GET_DEFAULT_LOCATION, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },

    onCompleted: (data) => {
      let { salesSummaryV2 } = data;
      const impVisibility =
        salesSummaryV2?.location?.role === "RD"
          ? "Access Granted RD"
          : salesSummaryV2?.location?.role === "DM"
          ? "Access Granted DM"
          : salesSummaryV2?.location?.role === "SM"
          ? "Access Granted SM"
          : "Access Denied";
      localStorage.setItem("impvisibility", impVisibility);
    },
  });
  const impIconVisibility = localStorage.getItem("impvisibility");
  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);
  return (
    <div style={{ flexDirection: "row" }}>
      {locationError.message ? (
        <ErrorMessage
          statusCode={locationError?.networkError?.statusCode}
          message={
            locationError?.networkError?.statusCode
              ? DATA_FETCH_ERROR
              : locationError.message
          }
        />
      ) : null}
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            className="closeButton"
            data-testid="close-button"
          >
            <CloseIcon style={{ fontSize: "30px" }} />
          </IconButton>
        ) : null}
        {locationLoading && (
          <CircularProgress
            className="locationIcon"
            color="inherit"
            size={30}
          />
        )}
        {impIconVisibility?.includes("Access Granted") && !locationLoading ? (
          <IconButton
            aria-label="close"
            className="locationIcon"
            onClick={handleImpClick}
          >
            <img alt="impersonation" className="imp-img" src={impIcon}></img>
          </IconButton>
        ) : null}
        <Typography component="span" variant="h6">
          {children}
        </Typography>
      </MuiDialogTitle>
    </div>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function useMediaQuery(query) {
  const getMatches = (query) => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      if (window.matchMedia(query) && window.matchMedia(query).matches)
        return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  useEffect(() => {
    function handleChange() {
      setMatches(getMatches(query));
    }
    const matchMedia = window.matchMedia(query);
    // Triggered at the first  load and if query changes
    handleChange();
    if (matchMedia && matchMedia.addEventListener)
      matchMedia.addEventListener("change", handleChange);
    return () => {
      if (matchMedia && matchMedia.removeEventListener)
        matchMedia.removeEventListener("change", handleChange);
    };
  }, [query]);

  return matches;
}

const UserProfile = ({
  open,
  handleClose,
  signOut,
  onConnectionError,
  userProfileinfo,
  setLoginUserInfo,
}) => {
  let user = {
    Name: userProfileinfo?.profileInfo?.data?.name,
    EmpId: userProfileinfo?.profileInfo?.data?.empId,
    Email: userProfileinfo?.profileInfo?.data?.email,
    Phone: userProfileinfo?.profileInfo?.data?.phone,
    profilePic: userProfileinfo?.profileInfo?.data?.profilePic,
  };
  let isHideLogout = false;
  let source = localStorage.getItem("source");
  if (source === "wow-crew") {
    isHideLogout = true;
  }

  const closeImpMenu = () => {
    setOpenImpMenu(false);
    setIsOpen(true);
  };
  const matches = useMediaQuery("(min-width: 600px)");
  const [isOpen, setIsOpen] = React.useState(false);
  const [openImpMenu, setOpenImpMenu] = React.useState(false);
  useEffect(() => {
    setIsOpen(open);
  }, [open, setIsOpen]);
  return (
    <div data-testid="user-profile">
      <Dialog
        fullScreen={!matches}
        fullWidth={false}
        height="auto"
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableEscapeKeyDown
        className="container"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{
            flexdirection: "row",
            padding: "1px",
          }}
          setIsOpen={setIsOpen}
          openImpMenu={openImpMenu}
          setOpenImpMenu={setOpenImpMenu}
          userProfileinfo={userProfileinfo}
        />

        <DialogContent>
          <div className="userProfile">
            <div className="paper">
              <div className="profile-Name">
                <Typography component="span" className="user-Title">
                  <h1>{user.Name}</h1>
                </Typography>
              </div>

              <div className="avatar-container">
                <div className="divider-div-style">
                  <Divider className="divider-Style" />
                </div>
                <div className="avatar-div-style">
                  <Box className="avatar-box">
                    <Avatar className="avatar-outline">
                      {user.EmpId && (
                        <Typography component="span" className="avatar-initial">
                          {getInitials(user.Name)}
                        </Typography>
                      )}
                    </Avatar>
                  </Box>
                </div>
                <div className="divider-div-style">
                  <Divider className="divider-Style" />
                </div>
              </div>
              {userProfileinfo?.loading && (
                <div style={{ padding: 20 }}>
                  <CircularProgress
                    variant="indeterminate"
                    size={40}
                    thickness={4}
                    value={100}
                  />
                </div>
              )}

              <div className="userdetails">
                <div className="userdetails-id">
                  <Typography component="span" className="empId">
                    {user.EmpId && <h2>ID: {user.EmpId}</h2>}
                  </Typography>
                </div>
              </div>

              <div>
                <Typography component="span" className="mailid">
                  <h3>{user.Email}</h3>
                </Typography>
              </div>
              <div
                className="userid"
                style={{
                  marginBottom: 15,
                }}
              >
                {user.Phone && (
                  <Typography component="span" className="phone">
                    <h3>{user.Phone}</h3>
                  </Typography>
                )}
              </div>
              {onConnectionError && (
                <div className="network-error">
                  You are not connected to the network
                </div>
              )}

              <div
                className="signout-button"
                style={{
                  paddingTop: matches ? "10vh" : "20vh",
                  background_color: "green",
                  paddingBottom: "15%",
                }}
              >
                {isHideLogout ? null : (
                  <Button
                    onClick={signOut}
                    variant="contained"
                    size="medium"
                    color="primary"
                    className="button"
                    data-testid="logout-button"
                  >
                    <Typography
                      component="span"
                      className="button-text"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      Sign Out
                    </Typography>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {userProfileinfo?.error &&
      userProfileinfo?.error?.code === "ERR_UP_INVALID_ACCESS_TOKEN" ? (
        <ErrorMessage
          statusCode={userProfileinfo?.error?.code}
          message={DATA_FETCH_ERROR}
        />
      ) : (
        userProfileinfo?.error && (
          <ErrorMessage message={userProfileinfo?.error?.text} />
        )
      )}

      {openImpMenu ? (
        <UserImpersonation
          impOpen={openImpMenu}
          handleImpClose={closeImpMenu}
        />
      ) : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userProfileinfo: state.userprofile,
  };
}

export default connect(mapStateToProps)(UserProfile);
