import React from "react";
import OmniChannelDetailTab from "../OmniChannelDetailTab/OmniChannelDetailTab";
import OmniChannelSFSTab from "../OmniChannelDetailTab/OmniChannelSFSTab";
import TabManagerGeneric from "../TabManagerGeneric/TabManagerGeneric";
import { BOPIS, SFS } from "../../common/constants";

const OmniTabManager = () => {
	const omnichannelTabs = [
		{
			tabTitle: BOPIS,
			component: OmniChannelDetailTab,
			isActive: true,
			tabId: "bopis"
		},
		{
			tabTitle: SFS,
			component: OmniChannelSFSTab,
			isActive: false,
			tabId: "sfs",
		}			
	];

  return (
    <>
      <TabManagerGeneric
        tabsData={omnichannelTabs}
        mainPathName="omnichannel-details"
      />
    </>
  );
};

export default OmniTabManager;
