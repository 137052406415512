import { SET_ACTIVE_TAB } from "../actions/types";

const initialState = "";

export const activeTabReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ACTIVE_TAB:
			return {
                ...state,
				currentActiveTab: action.payload,
			};
		default:
			return state;
	}
};

export default activeTabReducer;
