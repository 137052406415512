import { combineReducers } from "redux";
import authReducer from "./authReducers";
import salesSummaryReducer from "./salesSummaryReducer";
import userReducer from "./userReducer";
import userprofileReducer from "./userProfileReducer";
import activeTabReducer from "./activeTabReducres";

export default combineReducers({
	auth: authReducer,
	userInfo: userReducer,
	currentActiveTab: activeTabReducer,
	salesSummary: salesSummaryReducer,
	userprofile: userprofileReducer,
});
