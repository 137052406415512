import React, { useEffect } from "react";
import "./styles.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import DataRow from "./DataRow";
import { getStoreChecker } from "../../utils/utils";
import { getDate } from "../../utils/utils";
import { useLazyQuery } from "@apollo/client";
import {
  GET_DEFAULT_LOCATION,
  GET_OMNI_CHANNEL_SUMMARY,
} from "../../graphql/queries.js";
import ErrorMessage from "../ErrorMessage";
import {
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
} from "../../common/constants";

const OmniChannelSFSTab = ({ employeeInfo = {}, dateMatrix }) => {
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation?.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  const userEmail = employeeInfo.adUpn;
  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getOmniSfsSummery,
    {
      // eslint-disable-next-line no-unused-vars
      data: { omni: { omni: { bopis, sfs } = {} } = {} } = {},
      error: errors = {},
      loading: omni_sfs_loading,
    },
  ] = useLazyQuery(GET_OMNI_CHANNEL_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      dateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getOmniSfsSummery({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix === "YESTERDAY" ? "DAY" : dateMatrix,
          },
        },
      });
    }
  }, [userEmail, dateMatrix, selectedLocation, getOmniSfsSummery]);

  return (
    <div className="omni-detail-tab">
      {omni_sfs_loading && (
        <div className="omni-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!omni_sfs_loading && (
        <>
          {errors.message || locationError.message ? (
            <ErrorMessage
              statusCode={
                errors?.networkError?.statusCode ||
                locationError?.networkError?.statusCode
              }
              message={DATA_FETCH_ERROR}
            />
          ) : null}
          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}
          <div className="omni-detail-table-container">
            <div className="table-content">
              <div className="flex row">
                <div className="header-text">Ship From Store</div>
              </div>
              <div className="px-10 py-20">
                <DataRow
                  title="Orders Placed Online"
                  primaryValue={Math.round(
                    sfs?.ordersPlacedOnline ?? 0
                  ).toLocaleString("en")}
                />
                <DataRow
                  title="Orders Shipped"
                  primaryValue={Math.round(
                    sfs?.ordersShipped ?? 0
                  ).toLocaleString("en")}
                />
                <DataRow
                  title="Unit Fill Rate"
                  primaryValue={
                    Math.round(sfs?.fillRate ?? 0).toLocaleString("en") + `%`
                  }
                />
                <DataRow
                  title="Total Sales $"
                  primaryValue={
                    `$` + Number.parseFloat(sfs?.totalSales ?? 0).toFixed(2)
                  }
                />
                <DataRow
                  title="Total Sales Units"
                  primaryValue={Math.round(
                    sfs?.totalSalesUnits ?? 0
                  ).toLocaleString("en")}
                />
                <DataRow
                  title="Avg Completion Time (Hrs)"
                  primaryValue={Number.parseFloat(
                    sfs?.avgCompletionTime ?? 0
                  ).toFixed(2)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeInfo: state.auth.employeeInfo,
    dateMatrix: state?.salesSummary?.currentDateMatrix?.value,
  };
};
export default connect(mapStateToProps, undefined)(OmniChannelSFSTab);
