import React from "react";
import SummaryDetailTab from "../SummaryDetailTab";
import EfficiencyDetailTab from "../EfficiencyDetailTab";
import TabManagerGeneric from "../TabManagerGeneric/TabManagerGeneric";
import { LABOR, TREND } from "../../common/constants";

const LaborTabManager = () => {
	const laborTabs = [
		{
			tabTitle: LABOR,
			component: SummaryDetailTab,
			isActive: true,
			tabId: "labor"
		},
		{
			tabTitle: TREND,
			component: EfficiencyDetailTab,
			isActive: false,
			tabId: "trend"
		},
	];

  return (
    <>
       <TabManagerGeneric tabsData={laborTabs} mainPathName='labor-details'/>
    </>
  );
};

export default LaborTabManager;
