import React from "react";

const InventoryMerchDataRow = ({ title, skuNumber, unitQuantity, amount }) => {
  // console.log(title, "Merch data");
  return (
    <>
      <div className="data-row-container">
        <div className="flex row">
          <div className="sku-title">{title}</div>
        </div>
        <div className="flex row ic-merch-row-container">
          <div className="b-1-3 ic-merch-container">
            <div className="ic-merch-sub-value"></div>
            {/* <div className="ic-merch-Subtitlemain"></div> */}
          </div>
          <div className="b-1-3 ic-merch-container">
            <div className="ic-merch-sub-value">{unitQuantity}</div>
            {/* <div className="ic-merch-Subtitlemain">Units</div> */}
          </div>
          <div className="b-1-3 ic-merch-container">
            <div className="ic-merch-sub-value">{amount}</div>
            {/* <div className="ic-merch-Subtitlemain">$</div> */}
          </div>
        </div>
      </div>
      <div className="border-row" />
    </>
  );
};

export default InventoryMerchDataRow;
