import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ErrorMessage from "../ErrorMessage";
import { DATA_FETCH_ERROR, NO_CONNECTION_ERROR } from "../../common/constants";
import { useLazyQuery } from "@apollo/client";
import AddIcon from "../../images/add_icon.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import RemoveIcon from "../../images/remove_icon.png";
import { InventoryDataItem } from "../SummaryCard";
import { toTitleCase, getDate } from "../../utils/utils";
import { GET_INVENTORY_EXPANDABLE_VIEW } from "../../graphql/queries.js";
import useNetwork from "../UseNetwork";
import "./styles.scss";

const ShowRegionData = ({ region, title }) => {
  const {
    regionName,
    replaceUnitQuantity,
    damagesUnitQuantity,
    mosUnitQuantity,
    knownTheftUnitQuantity,
    shipmentUnitQuantity,
    candyUnitQuantity,
    districtList,
  } = region;
  const [isRegionExpanded, setRegionExpanded] = useState(
    title === "Region Summary" ? true : false
  );

  const toggleIsRegionExpanded = (e) => {
    e.stopPropagation();
    setRegionExpanded(!isRegionExpanded);
  };
  return (
    <div
      className={
        title !== "Region Summary"
          ? "inventory-control-container expanded-region"
          : "inventory-control-container unset-expanded-region"
      }
    >
      <div className="inventory-row-container">
        {title !== "Region Summary" ? (
          <>
            <div
              data-testid="toggleIsRegionExpanded"
              onClick={toggleIsRegionExpanded}
              className="inventory-title bold"
            >
              <div data-testid="add-remove-icon" className="add-remove-icon">
                {isRegionExpanded ? (
                  <img alt="remove icon" src={RemoveIcon} />
                ) : (
                  <img alt="add icon" src={AddIcon} />
                )}
              </div>
              <span>{toTitleCase(regionName)}</span>
            </div>
            <div
              className="data-row"
              style={{ justifyContent: "space-around" }}
            >
              <InventoryDataItem
                itemAmount={Math.round(replaceUnitQuantity ?? 0).toLocaleString(
                  "en"
                )}
                itemTitle="Replace"
              />
              <InventoryDataItem
                itemAmount={Math.round(damagesUnitQuantity ?? 0).toLocaleString(
                  "en"
                )}
                itemTitle="Damages"
              />
              <InventoryDataItem
                itemAmount={Math.round(mosUnitQuantity ?? 0).toLocaleString(
                  "en"
                )}
                itemTitle="MOS"
              />
              <InventoryDataItem
                itemAmount={Math.round(
                  knownTheftUnitQuantity ?? 0
                ).toLocaleString("en")}
                itemTitle="Known Theft"
              />

              <InventoryDataItem
                itemAmount={Math.round(
                  shipmentUnitQuantity ?? 0
                ).toLocaleString("en")}
                itemTitle="Shipment"
              />
              <InventoryDataItem
                itemAmount={Math.round(candyUnitQuantity ?? 0).toLocaleString(
                  "en"
                )}
                itemTitle="Candy"
              />
            </div>
            <div className="border-row" />
          </>
        ) : null}
      </div>
      {isRegionExpanded &&
        districtList.map((district) => {
          const { districtName, districtNumber } = district;
          return (
            <ShowDistrictData
              key={`${districtName}-${districtNumber}`}
              district={district}
              title={title}
            />
          );
        })}
    </div>
  );
};
const ShowDistrictData = ({ district, title }) => {
  const {
    districtName,
    replaceUnitQuantity,
    damagesUnitQuantity,
    mosUnitQuantity,
    knownTheftUnitQuantity,
    shipmentUnitQuantity,
    candyUnitQuantity,
    storeList,
  } = district;
  const [isDistrictExpanded, setDistrictExpanded] = useState(
    title === "District Summary" ? true : false
  );

  const toggleIsDistrictExpanded = (e) => {
    e.stopPropagation();
    setDistrictExpanded(!isDistrictExpanded);
  };

  return (
    <div className="inventory-control-container expanded-district">
      <div className="inventory-row-container">
        {title !== "District Summary" ? (
          <>
            <div
              data-testid="toggleIsDistrictExpanded"
              onClick={toggleIsDistrictExpanded}
              className="inventory-title bold"
            >
              <div data-testid="add-remove-icon" className="add-remove-icon">
                {isDistrictExpanded ? (
                  <img alt="remove icon" src={RemoveIcon} />
                ) : (
                  <img alt="add icon" src={AddIcon} />
                )}
              </div>
              <span>{toTitleCase(districtName)}</span>
            </div>
            <div
              className="data-row"
              style={{
                justifyContent: "space-around",
              }}
            >
              <InventoryDataItem
                itemAmount={Math.round(replaceUnitQuantity ?? 0).toLocaleString(
                  "en"
                )}
                itemTitle="Replace"
              />
              <InventoryDataItem
                itemAmount={Math.round(damagesUnitQuantity ?? 0).toLocaleString(
                  "en"
                )}
                itemTitle="Damages"
              />
              <InventoryDataItem
                itemAmount={Math.round(mosUnitQuantity ?? 0).toLocaleString(
                  "en"
                )}
                itemTitle="MOS"
              />

              <InventoryDataItem
                itemAmount={Math.round(
                  knownTheftUnitQuantity ?? 0
                ).toLocaleString("en")}
                itemTitle="Known Theft"
              />
              <InventoryDataItem
                itemAmount={Math.round(
                  shipmentUnitQuantity ?? 0
                ).toLocaleString("en")}
                itemTitle="Shipment"
              />
              <InventoryDataItem
                itemAmount={Math.round(candyUnitQuantity ?? 0).toLocaleString(
                  "en"
                )}
                itemTitle="Candy"
              />
            </div>
            <div className="border-row" />
          </>
        ) : null}
      </div>
      {isDistrictExpanded &&
        storeList.map((store) => {
          const { storeName, storeNumber } = store;
          return (
            <ShowStoreData key={`${storeName}-${storeNumber}`} store={store} />
          );
        })}
    </div>
  );
};

const ShowStoreData = ({ store }) => {
  const {
    storeName,
    replaceUnitQuantity,
    mosUnitQuantity,
    damagesUnitQuantity,
    knownTheftUnitQuantity,
    shipmentUnitQuantity,
    candyUnitQuantity,
  } = store;
  return (
    <div className="inventory-control-container expanded-store">
      <div className="inventory-row-container">
        <div className="inventory-title">{toTitleCase(storeName)}</div>
        <div className="data-row" style={{ justifyContent: "space-around" }}>
          <InventoryDataItem
            itemAmount={Math.round(replaceUnitQuantity ?? 0).toLocaleString(
              "en"
            )}
            itemTitle="Replace"
          />
          <InventoryDataItem
            itemAmount={Math.round(damagesUnitQuantity ?? 0).toLocaleString(
              "en"
            )}
            itemTitle="Damages"
          />
          <InventoryDataItem
            style={{ justifyContent: "flex-end" }}
            itemAmount={Math.round(mosUnitQuantity ?? 0).toLocaleString("en")}
            itemTitle="MOS"
          />
          <InventoryDataItem
            itemAmount={Math.round(knownTheftUnitQuantity ?? 0).toLocaleString(
              "en"
            )}
            itemTitle="Known Theft"
          />

          <InventoryDataItem
            itemAmount={Math.round(shipmentUnitQuantity ?? 0).toLocaleString(
              "en"
            )}
            itemTitle="Shipment"
          />
          <InventoryDataItem
            itemAmount={Math.round(candyUnitQuantity ?? 0).toLocaleString("en")}
            itemTitle="Candy"
          />
        </div>
        <div className="border-row" />
      </div>
    </div>
  );
};

const ExpandedInventorySummary = (props) => {
  const employeeInfo = props.employeeInfo;
  const primaryLocationName = props.primaryLocationName;
  const primaryLocationNumber = props.primaryLocationNumber;
  const selectedDateMatrix = props.selectedDateMatrix;
  // eslint-disable-next-line no-unused-vars
  const [isExpanded, setExpanded] = useState(true);
  const userEmail = employeeInfo.adUpn;
  const networkStatus = useNetwork();
  const [
    getInventoryExpandableView,
    {
      data: {
        inventoryControl: {
          inventoryControlExpandableView: { title = "", regionList = [] } = {},
        } = {},
      } = {},
      error = {},
      loading,
    },
  ] = useLazyQuery(GET_INVENTORY_EXPANDABLE_VIEW, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      primaryLocationName &&
      primaryLocationName !== "NA" &&
      selectedDateMatrix
    ) {
      getInventoryExpandableView({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: primaryLocationNumber,
              primaryLocationName: primaryLocationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: selectedDateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    primaryLocationName,
    selectedDateMatrix,
    getInventoryExpandableView,
    primaryLocationNumber,
  ]);

  // eslint-disable-next-line no-unused-vars
  // const toggleIsExpanded = (e) => {
  //   e.stopPropagation();
  //   setExpanded(!isExpanded);
  // };
  const stopNavigationFromSalesSummary = (e) => {
    e.stopPropagation();
  };
  const isDistrictManager = () =>
    title === "District Summary" &&
    regionList.length === 1 &&
    regionList[0].districtList.length > 0;

  if (props.getModalTitle) {
    if (title === "Chain Summary") {
      props.getModalTitle("Chain Inventory Control Summary");
    } else if (title === "Region Summary") {
      props.getModalTitle("Regional Inventory Control Summary");
    } else if (title === "District Summary") {
      props.getModalTitle("District Inventory Control Summary");
    } else {
      props.getModalTitle(title);
    }
  }

  useEffect(() => {
    setTimeout(function Pagecallerfun() {
      window.newrelic.addPageAction("Expanded-Inventory-Summary ", {
        result: "success",
      });
    }, 1000);
  }, []);

  return (
    <div
      data-testid="expanded-inventory-container"
      onClick={stopNavigationFromSalesSummary}
      className="expanded-inventory-summary"
    >
      {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
      {loading ? (
        <CircularProgress
          className="circular-progress"
          color="inherit"
          size={30}
        />
      ) : (
        !loading &&
        title && (
          <>
            {isExpanded && !regionList.length && (
              <div style={{ textAlign: "center" }}>No {title} data found.</div>
            )}
            {error.message ? (
              <ErrorMessage
                statusCode={error?.networkError?.statusCode}
                message={DATA_FETCH_ERROR}
              />
            ) : null}
            {isExpanded &&
              isDistrictManager() &&
              regionList[0].districtList.map((district) => {
                const { districtName, districtNumber } = district;
                if (title === "District Summary" && props.getTitleInModal) {
                  props.getTitleInModal(districtName);
                }
                return (
                  <ShowDistrictData
                    key={`${districtName}-${districtNumber}`}
                    district={district}
                    title={title}
                  />
                );
              })}
            {isExpanded &&
              !isDistrictManager() &&
              regionList.map((region) => {
                const { regionName, regionNumber } = region;
                if (title === "Region Summary" && props.getTitleInModal) {
                  props.getTitleInModal(regionName);
                } else if (title === "Chain Summary" && props.getTitleInModal) {
                  props.getTitleInModal("Chain");
                }
                return (
                  <ShowRegionData
                    key={`${regionName}-${regionNumber}`}
                    region={region}
                    title={title}
                  />
                );
              })}
          </>
        )
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
});

export default connect(mapStateToProps, undefined)(ExpandedInventorySummary);
