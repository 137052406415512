import React from "react";

const DataRow = ({ title, primaryValue, secondaryValue, isFirstRow }) => {
  return (
    <div className="flex row">
      <div className={`b-1-3 data-text ${isFirstRow ? "first-column" : "value-row first"}`}>
        {title}
      </div>
      <div className={`b-1-3 data-text second ${isFirstRow ? "first-row" : "value-row"}`}>
         {primaryValue}
      </div>
      <div className={`b-1-3 data-text third ${isFirstRow ? "first-row" : "value-row"}`}>
        {secondaryValue}
      </div>
    </div>
  );
};

export default DataRow;
