import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import {
  GET_DEFAULT_LOCATION,
  GET_MERCH_DATA,
  GET_MERCH_CLASS_SUMMARY,
  GET_MERCH_CLASS_METRICS,
} from "../../graphql/queries";
import SelectDropdown from "../SelectDropdown";
import MerchDataRow from "./MerchDataRow";
import {
  ALL_DEPARTMENTS,
  ALL_CLASSES,
  DATA_FETCH_ERROR,
  STORE_TOTAL,
  ASCENDING,
  DESCENDING,
  ONLOAN_INVALID_STOREID_ERROR,
  NO_CONNECTION_ERROR,
} from "../../common/constants";
import Typography from "@material-ui/core/Typography";

import "./styles.scss";
import { connect } from "react-redux";
import { getDate, toTitleCase, getStoreChecker } from "../../utils/utils";
import ErrorMessage from "../ErrorMessage";
import MerchTotalRow from "./MerchTotalRow";
import SortableMetrics from "./SortableMetrics";
import MerchClassDataRow from "./MerchClassDataRow";
import useNetwork from "../UseNetwork";

const initialMerchsortOptions = [
  {
    label: "Sales $",
    isChecked: true,
    value: "Sales",
  },
  {
    label: "Units",
    value: "Units",
    isChecked: false,
  },
  {
    label: "Mix",
    value: "Mix",
    isChecked: false,
  },
  {
    label: "Comp%",
    value: "Comp",
    isChecked: false,
  },
  {
    label: ASCENDING,
    value: "asc",
    isChecked: false,
  },
  {
    label: DESCENDING,
    value: "desc",
    isChecked: true,
  },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.6rem",
    color: state.isSelected ? "#FFFFFF" : "#353535",
    padding: 7,
  }),
};

const MerchDetailTab = ({ employeeInfo = {}, dateMatrix }) => {
  const networkStatus = useNetwork();
  const [storeMerchs, setStoreMerchs] = React.useState([]);
  const [selectedMerch, setSelectedMerch] = React.useState({});
  const [storeMerchClasses, setStoreMerchClasses] = React.useState([
    { label: ALL_CLASSES, value: ALL_CLASSES },
  ]);
  const [selectedMerchClass, setSelectedMerchClass] = React.useState({});
  const [isSelectedStoreTotal, setSelectedStoreTotal] = React.useState(false);
  const [merchsData, setMerchsData] = React.useState([]);
  const [merchsSortOptions, setMerchsSortOptions] = React.useState(
    initialMerchsortOptions
  );
  const [selectedMerchsData, setSelectedMerchsData] = React.useState([]);
  const [expandSkuData, setExpandSkuData] = React.useState(true);
  const [classLevelDataFlag, setClasslevelDataFlag] = React.useState(true);
  const [sortBy, setSortBy] = React.useState("Sales");
  const [sortType, setSortType] = React.useState("desc");
  const [selectedMerchNumber, setSelectedMerchNumber] = React.useState("");
  const [selectedMerchClassNumber, setSelectedMerchClassNumber] =
    React.useState("");
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  const userEmail = employeeInfo?.adUpn;
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getMerchSummary,
    {
      data: {
        departmentSummary: {
          departmentSalesReport = [],
          departmentTotalSalesReport: { compPercent, sales, units } = {},
        } = {},
      } = {},
      error = {},
      loading,
    },
  ] = useLazyQuery(GET_MERCH_DATA, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (departmentSalesReport && departmentSalesReport?.length > 0) {
      let newOptions = [
        { label: STORE_TOTAL, value: STORE_TOTAL },
        { label: ALL_DEPARTMENTS, value: ALL_DEPARTMENTS },
      ];
      if (departmentSalesReport?.length > 0) {
        setMerchsData(departmentSalesReport);
        setSelectedMerchsData(departmentSalesReport);
        departmentSalesReport.forEach((obj) => {
          if (obj["department"]) {
            let option = {};
            option.label =
              obj["department"]["deptName"] === "HBA"
                ? obj["department"]["deptNumber"] +
                  ": " +
                  obj["department"]["deptName"]
                : obj["department"]["deptNumber"] +
                  ": " +
                  toTitleCase(obj["department"]["deptName"]);
            option.value = obj["department"]["deptNumber"];
            newOptions.push(option);
          }
        });
        newOptions = newOptions
          .slice(0, 2)
          .concat(
            newOptions
              .slice(2)
              .sort((a, b) => parseInt(a.value) - parseInt(b.value))
          );
        setStoreMerchs(newOptions);
        setSelectedMerch(newOptions[1]);
        setExpandSkuData(false);
      }
    }
  }, [departmentSalesReport]);

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix
    ) {
      getMerchSummary({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            pageableSort: {
              sort: sortType,
              orderBy: sortBy,
            },
          },
        },
      });
    }
  }, [
    userEmail,
    selectedLocation,
    dateMatrix,
    getMerchSummary,
    sortType,
    sortBy,
  ]);
  const handleOnMerchChange = (option) => {
    let filteredMerchsData = [];
    if (![ALL_DEPARTMENTS, STORE_TOTAL].includes(option.label)) {
      let tempData = [];
      tempData = merchsData.filter(
        (obj) => obj.department.deptNumber === option.value
      );
      if (
        tempData.length > 0 &&
        (tempData[0].sales > 0 ||
          tempData[0].units > 0 ||
          tempData[0].mix > 0 ||
          tempData[0].compPercent > 0)
      ) {
        filteredMerchsData = tempData;

        setExpandSkuData(true);
        setSelectedStoreTotal(false);
        setSelectedMerchNumber(filteredMerchsData[0].department.deptNumber);
      }
    } else if (option.label === STORE_TOTAL) {
      setExpandSkuData(false);
      setSelectedMerchNumber("");
      setSelectedStoreTotal(true);
    } else {
      setExpandSkuData(false);
      setSelectedStoreTotal(false);
      filteredMerchsData = merchsData;
      setSelectedMerchNumber("");
    }
    setSelectedMerchsData(filteredMerchsData);
    setSelectedMerch(option);
    setSelectedMerchClass(storeMerchClasses[0]);
    setSelectedMerchClassNumber("");
    setClasslevelDataFlag(true);
  };

  const onApplyClickHandler = (selectedOptions) => {
    if (selectedOptions[0].isChecked) {
      setSortBy(selectedOptions[0].value);
    }
    if (selectedOptions[1].isChecked) {
      setSortType(selectedOptions[1].value);
    }
  };

  const updateMenuItems = (updateMenuItems) => {
    setMerchsSortOptions(updateMenuItems);
  };

  const [
    getMerchClassSummary,
    {
      data: { classLevelSummary = {} } = {},
      // eslint-disable-next-line no-unused-vars
      error: error_merch_class = {},
      loading: loading_merch_class,
    },
  ] = useLazyQuery(GET_MERCH_CLASS_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix &&
      selectedMerchNumber !== ""
    ) {
      getMerchClassSummary({
        variables: {
          request: {
            email: userEmail,
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            departmentNumber: selectedMerchNumber,
          },
        },
      });
    }
  }, [
    userEmail,
    dateMatrix,
    getMerchClassSummary,
    selectedLocation,
    selectedMerchNumber,
  ]);

  useEffect(() => {
    if (
      classLevelSummary &&
      classLevelSummary.classLevelClasses &&
      classLevelSummary.classLevelClasses?.length > 0
    ) {
      let classOptions = [{ label: ALL_CLASSES, value: ALL_CLASSES }];
      classLevelSummary.classLevelClasses.forEach((obj) => {
        if (obj["className"]) {
          let option = {};
          option.label = obj["classNo"] + ": " + toTitleCase(obj["className"]);
          option.value = obj["classNo"];
          classOptions.push(option);
        }
      });
      classOptions = classOptions
        .slice(0, 1)
        .concat(
          classOptions
            .slice(1)
            .sort((a, b) => parseInt(a.value) - parseInt(b.value))
        );
      setStoreMerchClasses(classOptions);
      setSelectedMerchClass(classOptions[0]);
      setSelectedMerchClassNumber("");
    }
  }, [classLevelSummary]);

  const handleOnMerchClassChange = (option) => {
    if (!ALL_CLASSES.includes(option.label)) {
      if (!classLevelReport?.classLevelClasses) {
        setClasslevelDataFlag(false);
      }
      if (classLevelReport?.classLevelClasses) {
        setClasslevelDataFlag(true);
      }
      setSelectedMerchClassNumber(option.value);
    } else {
      setSelectedMerchClassNumber("");
      setClasslevelDataFlag(true);
    }
    setSelectedMerchClass(option);
  };

  const [
    getMerchClassMetrics,
    {
      data: { classLevelSummary: { classLevelReport = {} } = {} } = {},
      error: error_merch_class_metric = {},
      loading: loading_merch_class_metric,
    },
  ] = useLazyQuery(GET_MERCH_CLASS_METRICS, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
    onError: (error) => {
      setSelectedMerchClassNumber("");
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix &&
      selectedMerchNumber !== "" &&
      selectedMerchClassNumber !== ""
    ) {
      getMerchClassMetrics({
        variables: {
          request: {
            email: userEmail,
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            departmentNumber: selectedMerchNumber,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            classNumber: selectedMerchClassNumber,
          },
        },
      });
    }
  }, [
    userEmail,
    dateMatrix,
    getMerchClassMetrics,
    selectedLocation,
    selectedMerchNumber,
    selectedMerchClassNumber,
  ]);

  useEffect(() => {
    if (!classLevelDataFlag) {
      if (!classLevelReport?.classLevelClasses) {
        setClasslevelDataFlag(false);
      }
      if (classLevelReport?.classLevelClasses) {
        setClasslevelDataFlag(true);
      }
    }
  }, [classLevelReport, classLevelDataFlag]);

  return (
    <div className="merch-detail-tab" data-testid="merch-detail-tab">
      {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
      {(loading || loading_merch_class_metric || loading_merch_class) && (
        <div className="merch-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!loading && !loading_merch_class_metric && !loading_merch_class && (
        <>
          {error.message || error_merch_class_metric.message ? (
            <ErrorMessage
              statusCode={
                error?.networkError?.statusCode ||
                locationError?.networkError?.statusCode
              }
              message={DATA_FETCH_ERROR}
            />
          ) : null}
          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}
          {!departmentSalesReport?.length > 0 ? (
            <div className="merch-noData-card">
              <div className="no-data-row">
                <Typography component="h1" variant="h1" className="label">
                  There is no data available for this timeframe. Please go back
                  and try a new selection{" "}
                </Typography>
              </div>
            </div>
          ) : (
            <>
              <div className="dropdown-container">
                <div className="dropdown-column">
                  <SelectDropdown
                    className="dropdown-row"
                    styles={customStyles}
                    onChange={handleOnMerchChange}
                    options={storeMerchs}
                    value={selectedMerch}
                    isSearchable={false}
                  />
                </div>
                {loading_merch_class ? (
                  <div className="merch-detail-loader">
                    <CircularProgress color="primary" size={30} />
                  </div>
                ) : (
                  selectedMerch.label !== ALL_DEPARTMENTS &&
                  selectedMerch.label !== STORE_TOTAL && (
                    <div className="dropdown-column class-level-dropdown">
                      <SelectDropdown
                        className="dropdown-row"
                        styles={customStyles}
                        onChange={handleOnMerchClassChange}
                        options={storeMerchClasses}
                        value={selectedMerchClass}
                        isSearchable={true}
                      />
                    </div>
                  )
                )}
              </div>
              <div className="table-container">
                {selectedMerch.value === ALL_DEPARTMENTS ? (
                  <>
                    <SortableMetrics
                      onApplyClickHandler={onApplyClickHandler}
                      sortOptions={merchsSortOptions}
                      updateMenuItems={updateMenuItems}
                    />
                    <div className="merch-sales-label-container" data-testid="merch-sales-label-container">
                      <div className="merch-sales-label">Sales $</div>
                      <div className="merch-sales-label">Units</div>
                      <div className="merch-sales-label">Mix</div>
                      <div className="merch-sales-label">Comp%</div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div
                  style={{ marginBottom: "7px" }}
                  className="border-row top-border-row"
                />
                <div
                  className="table-content merch-details-table"
                  style={
                    expandSkuData
                      ? {
                          position: "relative",
                          height: "95%",
                        }
                      : { overflow: "auto" }
                  }
                >
                  {(!selectedMerchsData?.length > 0 && !isSelectedStoreTotal) ||
                  !classLevelDataFlag ? (
                    <div className="merch-noData-card">
                      <div className="no-data-row">
                        <Typography
                          component="h1"
                          variant="h1"
                          className="label"
                        >
                          There is no data available for current selection.
                          Please try a new selection{" "}
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <>
                      {!expandSkuData && (
                        <MerchTotalRow
                          compPercent={compPercent}
                          sales={sales}
                          units={units}
                          isSelectedStoreTotal={isSelectedStoreTotal}
                        />
                      )}
                      {selectedMerchClassNumber !== "" &&
                      classLevelReport &&
                      classLevelReport.classLevelClasses ? (
                        <MerchClassDataRow
                          key={`${classLevelReport.classLevelClasses.classNo}`}
                          merchClassTitle={`${
                            classLevelReport.classLevelClasses.classNo
                          }: ${toTitleCase(
                            classLevelReport.classLevelClasses.className
                          )}`}
                          sales={classLevelReport.sales}
                          units={classLevelReport.units}
                          compPercent={classLevelReport.compPercent}
                          expandSkuData={expandSkuData}
                          selectedSkuMerchNumber={`${selectedMerchNumber}`}
                          selectedSkuMerchClassNumber={`${selectedMerchClassNumber}`}
                          selectedMerchOption={selectedMerch}
                          selectedMerchClass={selectedMerchClass}
                        />
                      ) : (
                        selectedMerchsData?.map(
                          (
                            {
                              department,
                              sales,
                              mix,
                              comp,
                              compPercent,
                              units,
                            },
                            index
                          ) => (
                            <MerchDataRow
                              key={`${department.deptNumber}-${index}`}
                              merchTitle={`${department.deptNumber}: ${
                                department.deptName === "HBA"
                                  ? department.deptName
                                  : toTitleCase(department.deptName)
                              }`}
                              sales={sales}
                              units={units}
                              mix={mix}
                              comp={comp}
                              compPercent={compPercent}
                              expandSkuData={expandSkuData}
                              selectedSkuMerchNumber={`${selectedMerchNumber}`}
                              selectedMerchOption={selectedMerch}
                              selectedMerchClass={selectedMerchClass}
                            />
                          )
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state?.auth?.employeeInfo,
  dateMatrix: state?.salesSummary?.currentDateMatrix?.value,
});

export default connect(mapStateToProps, undefined)(MerchDetailTab);
