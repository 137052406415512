import {
  FETCH_USER_DETAILS_PENDING,
  FETCH_USER_DETAILS_FAILED,
  FETCH_USER_DETAILS_SUCCESS,
} from "../actions/types";

const initialState = {
  profileInfo: {},
};

const userProfileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USER_DETAILS_PENDING:
      return {
        ...state,
        loading: true,
        error: "",
        profileInfo: {},
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        profileInfo: payload.data,
      };
    case FETCH_USER_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload?.error?.error?.message || { text: "Failed to Fetch" },
        profileInfo: {},
      };
    default:
      return state;
  }
};

export default userProfileReducer;
