import React, { useState, useEffect } from "react";
import "./styles.scss";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, Divider } from "@material-ui/core";
import {
  GET_SERVICES_TREND,
  GET_DEFAULT_LOCATION,
} from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { getStoreChecker, getDate } from "../../utils/utils";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import ErrorMessage from "../ErrorMessage";
import {
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
} from "../../common/constants";

function useMediaQuery(query) {
  const getMatches = (query) => {
    if (typeof window !== "undefined") {
      if (window.matchMedia(query) && window.matchMedia(query).matches)
        return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  useEffect(() => {
    const handleChange = () => {
      setMatches(getMatches(query));
    };
    const matchMedia = window.matchMedia(query);
    handleChange();
    if (matchMedia && matchMedia.addEventListener)
      matchMedia.addEventListener("change", handleChange);
    return () => {
      if (matchMedia && matchMedia.removeEventListener)
        matchMedia.removeEventListener("change", handleChange);
    };
  }, [query]);

  return matches;
}

const ServicesTrend = ({ employeeInfo = {}, dateMatrix }) => {
  const [selectedLocation, setSelectedLocation] = useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const matches = useMediaQuery("(max-width: 767px)");

  const [earPiercingXaxisData, setearPiercingXaxisData] = useState([]);
  const [earPiercingYaxisData, setearPiercingYaxisData] = useState([]);
  const [balloonsXaxis, setballoonsXaxis] = useState([]);
  const [balloonsYaxisData, setballoonsYaxis] = useState([]);

  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  const userEmail = employeeInfo.adUpn;

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getServicesTrendDetails,
    {
      data: { services: { servicesTrend = [] } = {} } = {},
      error: services_trend_error = {},
      loading: services_trend_loading,
    },
  ] = useLazyQuery(GET_SERVICES_TREND, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      dateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      setearPiercingXaxisData([]);
      setearPiercingYaxisData([]);
      setballoonsXaxis([]);
      setballoonsYaxis([]);
      getServicesTrendDetails({
        variables: {
          request: {
            email: userEmail,
            toDate: getDate("YESTERDAY", ""),
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            matrixType: dateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    dateMatrix,
    selectedLocation,
    getServicesTrendDetails,
    setearPiercingXaxisData,
    setearPiercingYaxisData,
    setballoonsXaxis,
    setballoonsYaxis,
  ]);

  const serviceTrendData = servicesTrend;
  const { balloons, earPiercing } = serviceTrendData;

  useEffect(() => {
    let earPiercingXaxis = [];
    let earPiercingYaxis = [];
    let balloonsXaxis = [];
    let balloonsYaxis = [];

    if (earPiercing) {
      for (let i = 0; i < earPiercing?.keyList.length; i++) {
        earPiercingXaxis.push(earPiercing?.keyList[i].key);
        earPiercingYaxis.push(earPiercing?.keyList[i].value);
      }
    }

    if (balloons) {
      for (let i = 0; i < balloons?.keyList.length; i++) {
        balloonsXaxis.push(balloons?.keyList[i].key);
        balloonsYaxis.push(balloons?.keyList[i].value);
      }
    }

    setearPiercingYaxisData(earPiercingYaxis);
    setearPiercingXaxisData(earPiercingXaxis);
    setballoonsXaxis(balloonsXaxis);
    setballoonsYaxis(balloonsYaxis);
  }, [
    balloons,
    earPiercing,
    setearPiercingYaxisData,
    setearPiercingXaxisData,
    setballoonsXaxis,
    setballoonsYaxis,
  ]);

  const balloonsData = {
    labels: balloonsXaxis,
    datasets: [
      {
        axis: "y",
        data: balloonsYaxisData,
        borderColor: "#3333ff",
        barPercentage: 1,
        categoryPercentage: 0.5,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",

        fill: "start",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 150);
          gradient.addColorStop(0, "#367bff");
          gradient.addColorStop(0.5, "#669cff");
          gradient.addColorStop(1, "#cbddf6");

          return gradient;
        },
      },
    ],
  };
  const balloonsLineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.formattedValue !== null) {
              label += Math.abs(
                Number(context.formattedValue.replace(/,/g, ""))
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          callback: function (value) {
            return Math.round(Math.abs(value * 100)) / 100;
          },
        },
      },
      x: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  const piercingData = {
    labels: earPiercingXaxisData,
    datasets: [
      {
        data: earPiercingYaxisData,
        borderColor: "#f68023",
        fill: "start",
        pointRadius: 5,
        pointHitRadius: 5,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = matches
            ? ctx.createLinearGradient(0, 175, 0, 0)
            : ctx.createLinearGradient(0, 250, 0, 0);
          gradient.addColorStop(1, "#ffa032");
          gradient.addColorStop("0.8", "#ffa030");
          gradient.addColorStop("0.6", "#ff9f39");
          gradient.addColorStop("0.5", "#ffc699");
          gradient.addColorStop("0.3", "#ffdfc6");
          gradient.addColorStop(0, "#ffffff");
          return gradient;
        },
      },
    ],
  };

  const piercingLineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.formattedValue !== null) {
              label += Math.round(
                Number(context.formattedValue.replace(/,/g, ""))
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          callback: function (value) {
            return Math.round(Math.abs(value) * 100) / 100;
          },
        },
      },
      x: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  return (
    <div data-testid="services-trend" className="services-trend-container">
      {services_trend_loading && (
        <div className="services-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!services_trend_loading && (
        <>
          {locationError.message || services_trend_error.message ? (
            <ErrorMessage
              statusCode={
                locationError?.networkError?.statusCode ||
                services_trend_error?.networkError?.statusCode
              }
              message={
                locationError?.networkError?.statusCode ||
                services_trend_error?.networkError?.statusCode
                  ? DATA_FETCH_ERROR
                  : services_trend_error.message
              }
            />
          ) : null}
          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}
          <div className="services-splitScreen">
            <div className="services-topPane">
              <div className="innerTopPane">
                <div className="titleTopPane">
                  <div className="title-row-top">
                    <Typography
                      component="span"
                      className="services-trend-titles"
                    >
                      Balloon Services
                    </Typography>
                  </div>
                </div>
                <div className="panel-wrapper">
                  <div className="contentTopPane">
                    <Line
                      data={balloonsData}
                      options={balloonsLineChartOptions}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Divider variant="middle" className="services-divider" />

            <div className="services-bottomPane">
              <div className="innerBottomPane">
                <div className="titleBottomPane">
                  <div className="title-row-bottom">
                    <Typography
                      component="span"
                      className="services-trend-titles"
                    >
                      Piercing Services
                    </Typography>
                  </div>
                </div>
                <div className="panel-wrapper">
                  <div className="contentBottomPane">
                    <Line
                      data={piercingData}
                      options={piercingLineChartOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeInfo: state.auth.employeeInfo,
    dateMatrix: state?.salesSummary?.currentDateMatrix?.value,
  };
};

export default connect(mapStateToProps, undefined)(ServicesTrend);
