import React, { useEffect } from "react";
import DataRow from "./DataRow";
import ErrorMessage from "../ErrorMessage";
import { DATA_FETCH_ERROR, ONLOAN_INVALID_STOREID_ERROR } from "../../common/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./styles.scss";
import { useLazyQuery } from "@apollo/client";
import { GET_DEFAULT_LOCATION, GET_LABOR_SUMMARY } from "../../graphql/queries";
import { connect } from "react-redux";
import { getDate, nullCheck,toFixedVal, getStoreChecker} from "../../utils/utils";

const SummaryDetailTab = ({ employeeInfo = {}, dateMatrix }) => {
  const userEmail = employeeInfo.adUpn;
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    if (userEmail  && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation,selectedStoreId, activeStorechecker]);

  const [
    getLaborSummary,
    {
      data: { labor: { labor = {} } = {} } = {},
      error: laborError = {},
      loading,
    },
  ] = useLazyQuery(GET_LABOR_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });
  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix
    ) {
      getLaborSummary({
        variables: {
          request: {
            email: userEmail,
            toDate: getDate("YESTERDAY", ""),
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            matrixType: dateMatrix,
          },
        },
      });
    }
  }, [userEmail, selectedLocation, dateMatrix, getLaborSummary]);
  const {
    firstTitle = undefined,
    secondTitle = undefined,
    laborDetails = {
      primaryActualPayrollHrs: undefined,
      primaryPayrollPlan: undefined,
      primaryAdjustedPayrollPlan: undefined,
      primaryInitialHoursPlan: undefined,
      primaryAdjustedHours: undefined,
      primaryELHours: undefined,
      primaryEfficiency: undefined,
      primaryOvertime: undefined,
      primaryOvertimePercentage: undefined,
      secondaryActualPayrollHrs: undefined,
      secondaryPayrollPlan: undefined,
      secondaryAdjustedPayrollPlan: undefined,
      secondaryInitialHoursPlan: undefined,
      secondaryAdjustedHours: undefined,
      secondaryELHours: undefined,
      secondaryEfficiency: undefined,
      secondaryOvertime: undefined,
      secondaryOvertimePercentage: undefined,
      primaryVarianceToPlanHours: undefined,
      secondaryVarianceToPlanHours: undefined,
    },
  } = labor || {};

  return (
    <div className="summary-detail-tab">
      {loading ? (
        <div className="summary-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      ) : null}
      {!loading && (
        <>
          {locationError.message || laborError.message ? (
            <ErrorMessage
              statusCode={
                locationError?.networkError?.statusCode ||
                laborError?.networkError?.statusCode
              }
              message={
                locationError?.networkError?.statusCode ||
                laborError?.networkError?.statusCode
                  ? DATA_FETCH_ERROR
                  : laborError.message
              }
            />
          ) : null}
          { activeStorechecker === false && selectedStoreId ? (
                <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
              ) : null}
          <div className="summary-detail-table-container">
            <div className="flex row">
              <div className="b-1-3 title-padding"></div>
              <div className="b-1-3 title-padding header-text border-right">
                {nullCheck(firstTitle)}
              </div>
              <div className="b-1-3 title-padding header-text">
                {nullCheck(secondTitle)}
              </div>
            </div>
            <div className="border-row"></div>

            <div className="table-content">
              <DataRow />
              <DataRow
                title="Initital Hours Plan"
                isFirstRow
                primaryValue={toFixedVal(laborDetails?.primaryInitialHoursPlan)}
                secondaryValue={toFixedVal(laborDetails?.secondaryInitialHoursPlan)}

              />
              <DataRow
                title="Adjusted Hours"
                primaryValue={toFixedVal(laborDetails?.primaryAdjustedHours)}
                secondaryValue={toFixedVal(
                  laborDetails?.secondaryAdjustedHours
                )}
              />
              <DataRow
                title="E/L Hours"
                primaryValue={toFixedVal(laborDetails?.primaryELHours)}
                secondaryValue={toFixedVal(laborDetails?.secondaryELHours)}
              />
              <DataRow
                title="Adjusted Payroll Plan"
                isFirstRow
                primaryValue={toFixedVal(
                  laborDetails?.primaryAdjustedPayrollPlan
                )}
                secondaryValue={toFixedVal(
                  laborDetails?.secondaryAdjustedPayrollPlan
                )}
              />
              <div className="divider-border"></div>
              <DataRow />
              <DataRow
                title="Actual Payroll Hours"
                isFirstRow
                primaryValue={toFixedVal(laborDetails?.primaryActualPayrollHrs)}
                secondaryValue={toFixedVal(
                  laborDetails?.secondaryActualPayrollHrs
                )}
              />
              <DataRow
                title="Var to Plan (Hrs)"
                isFirstRow
                primaryValue={toFixedVal(
                  laborDetails?.primaryVarianceToPlanHours
                )}
                secondaryValue={toFixedVal(
                  laborDetails?.secondaryVarianceToPlanHours
                )}
              />
              <DataRow
                title="Efficiency %"
                isFirstRow
                primaryValue={toFixedVal(laborDetails?.primaryEfficiency, "%", "end", 2)}            
                secondaryValue={toFixedVal(laborDetails?.secondaryEfficiency, "%", "end", 2)}

/>
              <DataRow />
              <div className="divider-border"></div>
              <DataRow />
              <DataRow
                title="Overtime"
                isFirstRow
                primaryValue={toFixedVal(laborDetails?.primaryOvertime)}
                secondaryValue={toFixedVal(laborDetails?.secondaryOvertime)}
              />
              <DataRow
                title="Overtime %"
                isFirstRow
                primaryValue={toFixedVal(laborDetails?.primaryOvertimePercentage, "%", "end", 2)}               
                secondaryValue={toFixedVal(laborDetails?.secondaryOvertimePercentage, "%", "end", 2)}

/>
              <DataRow />
              <div className="border-row"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeInfo: state.auth.employeeInfo,
    dateMatrix: state?.salesSummary?.currentDateMatrix?.value,
  };
};

export default connect(mapStateToProps, undefined)(SummaryDetailTab);
