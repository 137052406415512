import {
  SET_SELECTED_LOCATION,
  SET_SELECTED_DATE,
  SET_CURRENT_MATRIX,
} from "../actions/types";

const initialState = {
  selectedLocation: undefined,
  currentDateMatrix: "",
};

const salesSummaryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_LOCATION: {
      return { ...state, selectedLocation: payload };
    }
    case SET_SELECTED_DATE: {
      return { ...state, userSelectedDate: payload };
    }
    case SET_CURRENT_MATRIX: {
      return { ...state, currentDateMatrix: payload };
    }

    default:
      return state;
  }
};

export default salesSummaryReducer;
