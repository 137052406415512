import { ApolloClient, InMemoryCache } from "@apollo/client";
import { persistCache, SessionStorageWrapper } from "apollo3-cache-persist";

const cache = new InMemoryCache();

export const waitOnCache = persistCache({
  cache,
  storage: new SessionStorageWrapper(window.sessionStorage),
  maxSize: false,
});

export const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_SERVER_URL,
  credentials: "include",
  cache: cache,
  connectToDevTools: true,
  headers: {
    Authorization: localStorage.getItem("jwtToken"),
  },
});
