// import { LogLevel } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    // For b2c uncomment knownAuthorities
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES],
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI + "logout",
  },
  cache: {
    storeAuthStateInCookie: false,
    cacheLocation: "sessionStorage",
  },
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (level, message, containsPii) => {
  //       if (containsPii) {
  //         return;
  //       }
  //       switch (level) {
  //         case LogLevel.Error:
  //           console.error(message);
  //           return;
  //         case LogLevel.Info:
  //           console.info(message);
  //           return;
  //         case LogLevel.Verbose:
  //           console.debug(message);
  //           return;
  //         case LogLevel.Warning:
  //           console.warn(message);
  //           return;
  //         default:
  //           return;
  //       }
  //     },
  //   },
  // },
};

const loginRequest = [process.env.REACT_APP_MSAL_SCOPE];

export { msalConfig, loginRequest };
