import { useHistory } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const BackNavigator = () => {
  let history = useHistory();
  return (
    <>
      <ChevronLeftIcon
        data-testid="backnavigator"
        style={{ width: "1.5em", height: "1.5em", cursor: "pointer" }}
        onClick={() =>
          history.push({
            pathname: "/summary",
          })
        }
      ></ChevronLeftIcon>
    </>
  );
};

export default BackNavigator;
