import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../config/msalConfig";
import { CircularProgress } from "@material-ui/core";

function ErrorComponent({ error }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
        flexDirection: "column",
        padding: "5px",
      }}
    >
      <h2 className="mt-10">
        Something went wrong while authentication. Please try again later!
      </h2>
    </div>
  );
}

function LoadingComponent() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
        flexDirection: "column",
      }}
    >
      <CircularProgress color="primary" size={30} />
      <h2 className="mt-10">Please wait, Authentication in progress...</h2>
    </div>
  );
}

const MSALogin = (props) => {
  const search = useLocation().search;
  const activeStore = new URLSearchParams(search).get("storeId");
  const name = new URLSearchParams(search).get("source");
  if (activeStore || activeStore === "") {
    localStorage.setItem("source", "wow-crew");
    localStorage.setItem("storeId", activeStore);
  } else {
    localStorage.setItem("storeId", "");
    if (name) {
      localStorage.setItem("source", name);
    } else {
      localStorage.setItem("source", "");
    }
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <Redirect to="/summary" />
    </MsalAuthenticationTemplate>
  );
};

export default MSALogin;
