import React from "react";

const DataRow = ({ title, primaryValue, secondaryValue, isFirstRow }) => {
  return (
    <div className="flex row">
      <div className={`b-1-3 row-padding data-text ${isFirstRow ? "first-column" : ""}`}>
        {title}
      </div>
      <div className={`b-1-3 row-padding border-right data-text ${isFirstRow ? "first-row" : ""}`}>
         {primaryValue}
      </div>
      <div className={`b-1-3 row-padding data-text ${isFirstRow ? "first-row" : ""}`}>
        {secondaryValue}
      </div>
    </div>
  );
};

export default DataRow;
