import React, { useEffect } from "react";
import "./styles.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import DataRow from "./DataRow";
import {
  getDate,
  nullCheck,
  toFixedVal,
  getStoreChecker,
  roundValue,
} from "../../utils/utils";
import { useLazyQuery } from "@apollo/client";
import {
  GET_SALES_SUMMARY,
  GET_DEFAULT_LOCATION,
} from "../../graphql/queries.js";
import ErrorMessage from "../ErrorMessage";
import {
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
} from "../../common/constants";

const SalesDetailTab = ({ employeeInfo = {}, dateMatrix }) => {
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  const userEmail = employeeInfo.adUpn;

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getSummaryDetails,
    {
      data: { salesSummaryV2: { salesMatrix } = {} } = {},
      loading,
      error = {},
    },
  ] = useLazyQuery(GET_SALES_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      dateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getSummaryDetails({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
          },
        },
      });
    }
  }, [userEmail, dateMatrix, getSummaryDetails, selectedLocation]);

  const {
    firstTitle = undefined,
    secondTitle = undefined,
    sales = {
      primary: 0,
      secondary: 0,
      primaryVsPlan: 0,
      secondaryVsPlan: 0,
      secondaryCompPercentage: 0,
      primaryCompPercentage: 0,
    },
    transactions = {
      primary: 0,
      secondary: 0,
      primaryVsLY: 0,
      secondaryVsLY: 0,
      primaryTxnPct: 0,
      secondaryTxnPct: 0,
    },
    ads = {
      primary: 0,
      secondary: 0,
      primaryVsLY: 0,
      secondaryVsLY: 0,
      primaryAdsPct: 0,
      secondaryAdsPct: 0,
    },

    upt = {
      primary: 0,
      secondary: 0,
      primaryVsLY: 0,
      secondaryVsLY: 0,
      primaryUptPct: 0,
      secondaryUptPct: 0,
    },
    aur = {
      primary: 0,
      secondary: 0,
      primaryVsLY: 0,
      secondaryVsLY: 0,
      primaryAurPct: 0,
      secondaryAurPct: 0,
    },
  } = salesMatrix || {};

  return (
    <div className="sales-detail-tab">
      {loading && (
        <div className="sales-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!loading && (
        <>
          {error.message || locationError.message ? (
            <ErrorMessage
              statusCode={
                error?.networkError?.statusCode ||
                locationError?.networkError?.statusCode
              }
              message={DATA_FETCH_ERROR}
            />
          ) : null}
          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}
          <div className="sales-detail-table-container">
            <div className="flex row">
              <div className="b-1-3"></div>
              <div className="b-1-3 header-text second-header">
                {nullCheck(firstTitle)}
              </div>
              <div className="b-1-3 header-text third-header">
                {nullCheck(secondTitle)}
              </div>
            </div>
            <div className="border-row"></div>
            <div className="table-content">
              <div className="px-10 py-20 table-subrow-container">
                <DataRow
                  title="Sales"
                  isFirstRow
                  primaryValue={roundValue(sales?.primary, "$")}
                  secondaryValue={roundValue(sales?.secondary, "$")}
                />
                <DataRow
                  title="$ Var to Plan"
                  primaryValue={toFixedVal(
                    sales?.primaryVariancePlanAmt,
                    "$",
                    "start"
                  )}
                  secondaryValue={toFixedVal(
                    sales?.secondaryVariancePlanAmt,
                    "$",
                    "start"
                  )}
                />
                <DataRow
                  title="% to Plan"
                  primaryValue={toFixedVal(sales?.primaryVsPlan, "%", "end", 1)}
                  secondaryValue={toFixedVal(
                    sales?.secondaryVsPlan,
                    "%",
                    "end",
                    1
                  )}
                />
                <DataRow
                  title="$ Var to Comp"
                  primaryValue={toFixedVal(
                    sales?.primaryVarianceCompAmt,
                    "$",
                    "start"
                  )}
                  secondaryValue={toFixedVal(
                    sales?.secondaryVarianceCompAmt,
                    "$",
                    "start"
                  )}
                />
                <DataRow
                  title="Comp %"
                  primaryValue={toFixedVal(
                    sales?.primaryCompPercentage,
                    "%",
                    "end",
                    1
                  )}
                  secondaryValue={toFixedVal(
                    sales?.secondaryCompPercentage,
                    "%",
                    "end",
                    1
                  )}
                />
              </div>
              <div className="border-row"></div>
              <div className="px-10 py-20 table-subrow-container">
                <DataRow
                  title="Transactions"
                  isFirstRow
                  primaryValue={roundValue(transactions?.primary)}
                  secondaryValue={roundValue(transactions?.secondary)}
                />
                <DataRow
                  title="vs LY"
                  primaryValue={roundValue(transactions?.primaryVsLY)}
                  secondaryValue={roundValue(transactions?.secondaryVsLY)}
                />
                <DataRow
                  title="% to LY"
                  primaryValue={toFixedVal(
                    transactions?.primaryTxnPct,
                    "%",
                    "end",
                    1
                  )}
                  secondaryValue={toFixedVal(
                    transactions?.secondaryTxnPct,
                    "%",
                    "end",
                    1
                  )}
                />
              </div>
              <div className="border-row"></div>
              <div className="px-10 py-20 table-subrow-container">
                <DataRow
                  title="ADS"
                  isFirstRow
                  primaryValue={toFixedVal(ads?.primary, "$", "start", 2)}
                  secondaryValue={toFixedVal(ads?.secondary, "$", "start", 2)}
                />
                <DataRow
                  title="vs LY"
                  primaryValue={toFixedVal(ads?.primaryVsLY, "$", "start", 2)}
                  secondaryValue={toFixedVal(
                    ads?.secondaryVsLY,
                    "$",
                    "start",
                    2
                  )}
                />
                <DataRow
                  title="% to LY"
                  primaryValue={toFixedVal(ads?.primaryAdsPct, "%", "end", 1)}
                  secondaryValue={toFixedVal(
                    ads?.secondaryAdsPct,
                    "%",
                    "end",
                    1
                  )}
                />
              </div>
              <div className="border-row"></div>
              <div className="px-10 py-20 table-subrow-container">
                <DataRow
                  title="UPT"
                  isFirstRow
                  primaryValue={toFixedVal(upt?.primary, "", "", 1)}
                  secondaryValue={toFixedVal(upt?.secondary, "", "", 1)}
                />
                <DataRow
                  title="vs LY"
                  primaryValue={toFixedVal(upt?.primaryVsLY, "", "", 1)}
                  secondaryValue={toFixedVal(upt?.secondaryVsLY, "", "", 1)}
                />
                <DataRow
                  title="% to LY"
                  primaryValue={toFixedVal(upt?.primaryUptPct, "%", "end", 1)}
                  secondaryValue={toFixedVal(
                    upt?.secondaryUptPct,
                    "%",
                    "end",
                    1
                  )}
                />
              </div>
              <div className="border-row"></div>
              <div className="px-10 py-20 table-subrow-container">
                <DataRow
                  title="AUR"
                  isFirstRow
                  primaryValue={toFixedVal(aur?.primary, "$", "start", 2)}
                  secondaryValue={toFixedVal(aur?.secondary, "$", "start", 2)}
                />
                <DataRow
                  title="vs LY"
                  primaryValue={toFixedVal(aur?.primaryVsLY, "$", "start", 2)}
                  secondaryValue={toFixedVal(
                    aur?.secondaryVsLY,
                    "$",
                    "start",
                    2
                  )}
                />
                <DataRow
                  title="% to LY"
                  primaryValue={toFixedVal(aur?.primaryAurPct, "%", "end", 1)}
                  secondaryValue={toFixedVal(
                    aur?.secondaryAurPct,
                    "%",
                    "end",
                    1
                  )}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeInfo: state.auth.employeeInfo,
    dateMatrix: state.salesSummary.currentDateMatrix.value,
  };
};
export default connect(mapStateToProps, undefined)(SalesDetailTab);
