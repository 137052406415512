/* eslint-disable no-unused-vars */
import React from "react";
import ServicesDetailTab from "../ServicesDetailTab/ServicesDetailTab";
import ServicesMerchDetailTab from "../ServicesMerchDetailTab/ServicesMerchDetailTab";
import ServicesTrend from "../ServicesTrend/ServicesTrend";
import TabManagerGeneric from "../TabManagerGeneric/TabManagerGeneric";
import { DRIVERS, MERCH, TREND } from "../../common/constants";

const ServicesTabManager = () => {
	const servicesTabs = [
		{
			tabTitle: DRIVERS,
			component: ServicesDetailTab,
			isActive: true,
			tabId: "drivers"
		},
		{
			tabTitle: TREND,
			component: ServicesTrend,
			isActive: false,
			tabId: "trend",
		},
		{
			tabTitle: MERCH,
			component: ServicesMerchDetailTab,
			isActive: false,
			tabId: "merch",
		},		
	];

  return (
    <>
      <TabManagerGeneric
        tabsData={servicesTabs}
        mainPathName="services-details"
      />
    </>
  );
};

export default ServicesTabManager;
