import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import {
  GET_DEFAULT_LOCATION,
  GET_SERVICES_MERCH_SKU_DETAILS,
} from "../../graphql/queries";
import SelectDropdown from "../SelectDropdown";
import {
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
  NO_CONNECTION_ERROR,
} from "../../common/constants";
import "./styles.scss";
import { connect } from "react-redux";
import {
  getDate,
  getStoreChecker,
  getServicesMerchDropdownOptions,
} from "../../utils/utils";
import ErrorMessage from "../ErrorMessage";
import ServicesMerchDataRow from "./ServicesMerchDataRow";
import useNetwork from "../UseNetwork";
import Typography from "@material-ui/core/Typography";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.6rem",
    color: state.isSelected ? "#FFFFFF" : "#353535",
    padding: 7,
  }),
};

const ServicesMerchDetailTab = ({ employeeInfo = {}, dateMatrix }) => {
  const networkStatus = useNetwork();
  const [serviceMerchDropdownOptions, setServiceMerchDropdownOptions] =
    React.useState(getServicesMerchDropdownOptions);
  const [selectedServiceMerchOption, setSelectedServiceMerchOption] =
    React.useState({});
  const [selectedServiceMerchType, setSelectedServiceMerchType] =
    React.useState("");
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  const userEmail = employeeInfo?.adUpn;
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);

  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  useEffect(() => {
    setServiceMerchDropdownOptions(getServicesMerchDropdownOptions);
    setSelectedServiceMerchOption(getServicesMerchDropdownOptions[0]);
    setSelectedServiceMerchType(
      getServicesMerchDropdownOptions[0].value.toUpperCase()
    );
  }, [dateMatrix]);

  const [
    getServicesMerchSkuSummary,
    {
      data: { servicesSkuSummary: { servicesSkuSalesReport = [] } = {} } = {},
      error: serviceError = {},
      loading: serviceLoading,
    },
  ] = useLazyQuery(GET_SERVICES_MERCH_SKU_DETAILS, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix &&
      selectedServiceMerchType !== ""
    ) {
      getServicesMerchSkuSummary({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix === "YESTERDAY" ? "DAY" : dateMatrix,
            servicesTypeName: selectedServiceMerchType,
          },
        },
      });
    }
  }, [
    userEmail,
    selectedLocation,
    dateMatrix,
    selectedServiceMerchType,
    getServicesMerchSkuSummary,
  ]);

  const handleOnMerchChange = (option) => {
    setSelectedServiceMerchOption(option);
    setSelectedServiceMerchType(option.value.toUpperCase());
  };

  return (
    <div className="services-merch-detail-tab" data-testid="services-merch-detail-tab">
      {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
      {serviceLoading && (
        <div className="services-merch-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!serviceLoading && (
        <>
          {serviceError.message && (
            <ErrorMessage
              statusCode={
                serviceError?.networkError?.statusCode ||
                locationError?.networkError?.statusCode
              }
              message={DATA_FETCH_ERROR}
            />
          )}

          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}
          {!servicesSkuSalesReport?.length > 0 ? (
            <div className="services-merch-noData-card">
              <div className="no-data-row">
                <Typography component="h1" variant="h1" className="label">
                  There is no data available for this timeframe. Please go back
                  and try a new selection{" "}
                </Typography>
              </div>
            </div>
          ) : (
            <>
              <div className="dropdown-container">
                <div className="dropdown-column">
                  <SelectDropdown
                    data-testid="service-merch-select-dropdown"
                    className="dropdown-row"
                    styles={customStyles}
                    onChange={handleOnMerchChange}
                    options={serviceMerchDropdownOptions}
                    value={selectedServiceMerchOption}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className="table-container">
                <div className="services-merch-label-container flex row">
                  
                  <div className="services-merch-sales-label">Sales U</div>
                  <div className="services-merch-sales-label">Sales $</div>
                </div>
                <div className="border-row top-border-row"></div>
                <div
                  className={
                    !servicesSkuSalesReport?.length > 0
                      ? "table-content-nodata"
                      : "table-content"
                  }
                >
                  {servicesSkuSalesReport?.length > 0 &&
                    servicesSkuSalesReport.map(
                      (
                        { itemNumber, itemName, totalSalesUnits, salesAmount },
                        index
                      ) => {
                        return (
                          <ServicesMerchDataRow
                            key={`${itemNumber}-${index}`}
                            serviceitemNumber={itemNumber}
                            serviceitemName={itemName}
                            servicesalesu={totalSalesUnits}
                            servicesalesdollar={salesAmount}
                          />
                        );
                      }
                    )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
  dateMatrix: state?.salesSummary?.currentDateMatrix?.value,
});

export default connect(mapStateToProps, undefined)(ServicesMerchDetailTab);
