import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import "./styles.scss";
import salesHierarchyIcon from "../../images/sales-hierarchy-icon.png";
import modalCloseIcon from "../../images/modal-close-icon.png";
import ExpandedInventorySummary from "../ExpandedInventorySummary";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "444px",
    border: "2px solid #fff",
    height: "calc(100% - 64px)",
    padding: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    boxShadow:
      "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
  },
}));

const InventoryModal = (props) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [modalTitle, setModalTitle] = useState(props.title);
  const [titleInModal, setTitleInModal] = useState(props.titleInModal);

  let ExpandeSummaryTopRowContainer = "";
  let ExpandeSummaryContentContainer = "";

  const body = (
    <div className="modal-body-container">
      <div className="modal-header-container">
        <img
          alt="close modal icon"
          className="modal-close-btn-icon"
          src={modalCloseIcon}
          onClick={handleClose}
        />
        <h1
          className="modal-heading"
          id="simple-modal-title"
          data-testid="simple-modal-title"
        >
          {modalTitle != null ? modalTitle : null}
        </h1>
      </div>
      <div className="modal-content">
        {props.ExpandedInventorySummaryComponent === "true" ? (
          <div className="modal-content-summaryData-title">
            <h1 className="modal-summaryData-title">{titleInModal}</h1>
          </div>
        ) : props.titleInModal ? (
          <div className="modal-content-summaryData-title">
            <h1 className="modal-summaryData-title">{props.titleInModal}</h1>
          </div>
        ) : null}

        {props.modalContentOther ? (
          <TableContainer
            className={`modal-content-data-row table-container-wrapper ${ExpandeSummaryTopRowContainer} `}
            style={{ height: "100%", borderBottom: 0 }}
          >
            <Table
              style={{ minWidth: "767px", height: "100%" }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>{props.modalContentOther}</TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    className={`modal-content-summaryData-container ${ExpandeSummaryContentContainer}`}
                    style={{ padding: 0 }}
                  >
                    {props.ExpandedInventorySummaryComponent === "true" ? (
                      <ExpandedInventorySummary
                        primaryLocationNumber={props.primaryLocationNumber}
                        primaryLocationName={props.primaryLocationName}
                        selectedDateMatrix={props.selectedDateMatrix}
                        getModalTitle={setModalTitle}
                        getTitleInModal={setTitleInModal}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </div>
    </div>
  );

  return (
    <div className="modal-container">
      <button className="modal-open-btn" type="button" onClick={handleOpen}>
        <img
          alt="open modal"
          className="modal-open-btn-img"
          src={salesHierarchyIcon}
        />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default InventoryModal;
