import React, { useEffect } from "react";
import { getStoreChecker, toTitleCase, roundValue } from "../../utils/utils";
import { useLazyQuery } from "@apollo/client";
import { GET_DEFAULT_LOCATION } from "../../graphql/queries";
import { connect } from "react-redux";
import ErrorMessage from "../ErrorMessage";
import {
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
} from "../../common/constants";

const ServicesMerchDataRow = ({
  employeeInfo = {},
  dateMatrix,
  serviceitemNumber,
  serviceitemName,
  servicesalesu,
  servicesalesdollar,
}) => {
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const userEmail = employeeInfo.adUpn;
  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );
  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  return (
    <>
      {locationError.message ? (
        <ErrorMessage
          statusCode={locationError?.networkError?.statusCode}
          message={DATA_FETCH_ERROR}
        />
      ) : null}
      {activeStorechecker === false && selectedStoreId ? (
        <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
      ) : null}
      <div data-testid="service-merch-data-row" className="data-row-container">
        <div className="flex row">
          <div className="services-merch-title">
            {" "}
            {`${toTitleCase(serviceitemName)} ${serviceitemNumber}`}{" "}
          </div>
        </div>
        <div className="services-merch-sales-data flex row">
          <div className="services-merch-sales-datavalues">
            <div className="services-merch-sales-value">
              {roundValue(servicesalesu).toLocaleString("en")}
            </div>
          </div>
          <div className="services-merch-sales-datavalues">
            {" "}
            <div className="services-merch-sales-value">
              {`$ ` + Math.round(servicesalesdollar).toLocaleString("en")}
            </div>
          </div>
        </div>
      </div>
      <div className="border-row bottom-border-row" />
    </>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
  dateMatrix: state?.salesSummary?.currentDateMatrix?.value,
});

export default connect(mapStateToProps, undefined)(ServicesMerchDataRow);
