import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import {
  GET_DEFAULT_LOCATION,
  GET_MERCH_DATA,
  GET_INVENTORY_CONTROL_SKU_SUMMARY,
  GET_INVENTORY_CONTROL_DRIVER_TAB_DETAIL,
  GET_INVENTORY_CONTROL_DEPARTMENT_SUMMARY,
} from "../../graphql/queries";
import SelectDropdown from "../SelectDropdown";
import DataRow from "../InventoryControlDetailsTabs/DataRow";
import {
  ALL_DEPARTMENTS,
  DATA_FETCH_ERROR,
  STORE_TOTAL,
  ONLOAN_INVALID_STOREID_ERROR,
  NO_CONNECTION_ERROR,
  INVENTORY_CONTROL_TOTAL_ADJUSTMENTS_SORT_OPTIONS,
  INVENTORY_CONTROL_TOTAL_ADJUSTMENTS_SORT_DROPDOWN_ARRAY,
  DAMAGES,
  SALES,
} from "../../common/constants";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { getDate, toTitleCase, getStoreChecker } from "../../utils/utils";
import ErrorMessage from "../ErrorMessage";
import SkuRow from "../InventoryControlDetailsTabs/SkuRow";
import useNetwork from "../UseNetwork";
import SkuSortableMetrice from "../InventoryControlDetailsTabs/SkuSortableMetrice";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.6rem",
    color: state.isSelected ? "#FFFFFF" : "#353535",
    padding: 7,
  }),
};

const Damages = ({ employeeInfo = {}, dateMatrix }) => {
  const networkStatus = useNetwork();
  const [storeMerchs, setStoreMerchs] = React.useState([]);
  const [selectedMerch, setSelectedMerch] = React.useState({});
  const [merchsData, setMerchsData] = React.useState([]);
  const [expandSkuData, setExpandSkuData] = React.useState(true);
  const [pageSort, setPageSort] = useState("desc");
  const [pageOrder, setPageOrder] = useState("units");
  const [selectedMerchNumber, setSelectedMerchNumber] = React.useState("");
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  const userEmail = employeeInfo?.adUpn;
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getMerchSummary,
    {
      data: { departmentSummary: { departmentSalesReport = [] } = {} } = {},
      error = {},
      loading,
    },
  ] = useLazyQuery(GET_MERCH_DATA, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (departmentSalesReport && departmentSalesReport?.length > 0) {
      let newOptions = [{ label: ALL_DEPARTMENTS, value: ALL_DEPARTMENTS }];
      if (departmentSalesReport?.length > 0) {
        setMerchsData(departmentSalesReport);
        departmentSalesReport.forEach((obj) => {
          if (obj["department"]) {
            let option = {};
            option.label =
              obj["department"]["deptName"] === "HBA"
                ? obj["department"]["deptNumber"] +
                  ": " +
                  obj["department"]["deptName"]
                : obj["department"]["deptNumber"] +
                  ": " +
                  toTitleCase(obj["department"]["deptName"]);
            option.value = obj["department"]["deptNumber"];
            newOptions.push(option);
          }
        });
        newOptions = newOptions
          .slice(0, 2)
          .concat(
            newOptions
              .slice(2)
              .sort((a, b) => parseInt(a.value) - parseInt(b.value))
          );
        setStoreMerchs(newOptions.filter((data) => data.label !== STORE_TOTAL));
        setSelectedMerch(newOptions[0]);
        setExpandSkuData(false);
      }
    }
  }, [departmentSalesReport]);

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix
    ) {
      getMerchSummary({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            pageableSort: {
              sort: pageSort,
              orderBy: SALES,
            },
          },
        },
      });
    }
  }, [userEmail, selectedLocation, dateMatrix, getMerchSummary, pageSort]);

  const handleOnMerchChange = (option) => {
    let filteredMerchsData = [];
    if (![ALL_DEPARTMENTS].includes(option.label)) {
      let tempData = [];
      tempData = merchsData.filter(
        (obj) => obj.department.deptNumber === option.value
      );
      if (tempData.length > 0) {
        filteredMerchsData = tempData;
        setSelectedMerchNumber(filteredMerchsData[0].department.deptNumber);
      }
    } else {
      filteredMerchsData = merchsData;
      setSelectedMerchNumber("");
    }
    setSelectedMerch(option);
  };

  const [
    getInventoryControlSkuData,
    {
      data: {
        inventoryControlSkuSummary: { inventoryControlSkuSalesReport } = {},
      } = {},
      error: skuError,
      loading: skuLoading,
    },
  ] = useLazyQuery(GET_INVENTORY_CONTROL_SKU_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix &&
      pageSort &&
      pageOrder
    ) {
      getInventoryControlSkuData({
        variables: {
          request:
            selectedMerchNumber !== ""
              ? {
                  email: userEmail,
                  location: {
                    primaryLocationNumber: selectedLocation?.locationNo,
                    primaryLocationName: selectedLocation?.locationName,
                  },
                  toDate: getDate("YESTERDAY", ""),
                  matrixType: dateMatrix,
                  adjustmentCategory: DAMAGES,
                  departmentNumber: selectedMerchNumber,
                  pageableSort: {
                    sort: pageSort,
                    orderBy: pageOrder,
                  },
                }
              : {
                  email: userEmail,
                  location: {
                    primaryLocationNumber: selectedLocation?.locationNo,
                    primaryLocationName: selectedLocation?.locationName,
                  },
                  toDate: getDate("YESTERDAY", ""),
                  matrixType: dateMatrix,
                  adjustmentCategory: DAMAGES,
                  pageableSort: {
                    sort: pageSort,
                    orderBy: pageOrder,
                  },
                },
        },
      });
    }
  }, [
    userEmail,
    selectedLocation,
    dateMatrix,
    getInventoryControlSkuData,
    selectedMerchNumber,
    pageSort,
    pageOrder,
  ]);

  function onApplyClickHandler(selectedOptions) {
    const { value, orderBy } = selectedOptions[0];
    setPageSort(value);
    setPageOrder(orderBy);
  }

  const [
    getInventoryControlDriversTabDetails,
    {
      data: { inventoryControl: { invControlDrivers } = {} } = {},
      error: drivererror,
      loading: driverloading,
    },
  ] = useLazyQuery(GET_INVENTORY_CONTROL_DRIVER_TAB_DETAIL, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      dateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      getInventoryControlDriversTabDetails({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    dateMatrix,
    getInventoryControlDriversTabDetails,
    selectedLocation,
  ]);

  const [
    getInventoryControlDepartmentReport,
    {
      data: {
        inventoryControlDepartmentSummary: {
          inventoryControlDepartmentTotalReport,
        } = {},
      } = {},
      error: depterror,
      loading: deptloading,
    },
  ] = useLazyQuery(GET_INVENTORY_CONTROL_DEPARTMENT_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      dateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      selectedMerchNumber
    ) {
      getInventoryControlDepartmentReport({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            adjustmentCategory: DAMAGES,
            departmentNumber: selectedMerchNumber,
          },
        },
      });
    }
  }, [
    userEmail,
    dateMatrix,
    getInventoryControlDepartmentReport,
    selectedLocation,
    selectedMerchNumber,
  ]);

  return (
    <div className="damages-detail-tab">
      {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
      {loading && (
        <div className="damages-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!loading && (
        <>
          {error.message ? (
            <ErrorMessage
              statusCode={
                error?.networkError?.statusCode ||
                locationError?.networkError?.statusCode
              }
              message={DATA_FETCH_ERROR}
            />
          ) : null}
          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}
          {!departmentSalesReport?.length > 0 ? (
            <div className="damages-noData-card">
              <div className="damages-no-data-row">
                <Typography component="h1" variant="h1" className="label">
                  There is no data available for this timeframe. Please go back
                  and try a new selection{" "}
                </Typography>
              </div>
            </div>
          ) : (
            <>
              <div className="damages-dropdown-container">
                <div className="damages-dropdown-column">
                  <SelectDropdown
                    className="damages-dropdown-row"
                    styles={customStyles}
                    onChange={handleOnMerchChange}
                    options={storeMerchs}
                    value={selectedMerch}
                    isSearchable={false}
                  />
                </div>
              </div>
              <div className="damages-table-container">
                <div
                  style={{ marginBottom: "8px" }}
                  className="damages-border-row damages-top-border-row"
                />
                <div
                  className="damages-table-content"
                  style={
                    expandSkuData
                      ? { position: "relative", height: "95%" }
                      : { overflow: "unset" }
                  }
                >
                  {!drivererror && selectedMerchNumber === "" ? (
                    !driverloading ? (
                      <DataRow
                        title={STORE_TOTAL}
                        unitQuantity={Math.round(
                          invControlDrivers?.damages?.unitQuantity ?? 0
                        ).toLocaleString("en")}
                        itemCount={Math.round(
                          invControlDrivers?.damages?.itemCount ?? 0
                        ).toLocaleString("en")}
                        adjustmentsAmount={
                          `$` +
                          Math.round(
                            invControlDrivers?.damages?.adjustmentsAmount ?? 0
                          ).toLocaleString("en")
                        }
                        isFirstRow={false}
                      />
                    ) : (
                      <CircularProgress color="primary" size={30} />
                    )
                  ) : (
                    !depterror &&
                    (!deptloading ? (
                      <DataRow
                        title={
                          selectedMerch.label === ALL_DEPARTMENTS
                            ? STORE_TOTAL
                            : selectedMerch.label
                        }
                        unitQuantity={Math.round(
                          selectedMerch.label === ALL_DEPARTMENTS
                            ? invControlDrivers?.damages?.unitQuantity ?? 0
                            : inventoryControlDepartmentTotalReport?.unitQuantity ??
                                0
                        ).toLocaleString("en")}
                        itemCount={Math.round(
                          selectedMerch.label === ALL_DEPARTMENTS
                            ? invControlDrivers?.damages?.itemCount ?? 0
                            : inventoryControlDepartmentTotalReport?.itemCount ??
                                0
                        ).toLocaleString("en")}
                        adjustmentsAmount={
                          `$` +
                          Math.round(
                            selectedMerch.label === ALL_DEPARTMENTS
                              ? invControlDrivers?.damages?.adjustmentsAmount ??
                                  0
                              : inventoryControlDepartmentTotalReport?.adjustmentAmount ??
                                  0
                          ).toLocaleString("en")
                        }
                        isFirstRow={false}
                      />
                    ) : (
                      <CircularProgress color="primary" size={30} />
                    ))
                  )}

                  <div className="damages-border-row damages-top-border-row" />
                </div>

                <div className="longsku-data">
                  {!skuError &&
                    !skuLoading &&
                    inventoryControlSkuSalesReport?.length !== 0 && (
                      <SkuSortableMetrice
                        dropdownData={
                          INVENTORY_CONTROL_TOTAL_ADJUSTMENTS_SORT_OPTIONS
                        }
                        sortOptionsLabelData={
                          INVENTORY_CONTROL_TOTAL_ADJUSTMENTS_SORT_DROPDOWN_ARRAY
                        }
                        onApplyClickHandler={onApplyClickHandler}
                      />
                    )}
                  <div className="longsku-row-container">
                    {!skuError &&
                    inventoryControlSkuSalesReport?.length !== 0 ? (
                      !skuLoading ? (
                        inventoryControlSkuSalesReport?.map((data, index) => (
                          <SkuRow
                            key={index}
                            title={toTitleCase(data.itemName)}
                            skuNumber={Math.round(data.itemNumber ?? 0)}
                            unitQuantity={Math.round(data.unitQuantity ?? 0)}
                            amount={
                              `$` +
                              Math.round(
                                data.adjustmentAmount ?? 0
                              ).toLocaleString("en")
                            }
                          />
                        ))
                      ) : (
                        <CircularProgress color="primary" size={30} />
                      )
                    ) : (
                      <div className="damages-noData-card">
                        <div className="damages-no-data-row">
                          <Typography
                            component="h1"
                            variant="h1"
                            className="label"
                          >
                            There is no data available for this timeframe.
                            Please go back and try a new selection{" "}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
  dateMatrix: state.salesSummary.currentDateMatrix.value,
});

export default connect(mapStateToProps, undefined)(Damages);
