import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import ErrorPage from "../ErrorMessage/ErrorPage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const employeeInfo = useSelector((state) => state.auth.employeeInfo) || {};
  const isSearchUserError =
    useSelector((state) => state.auth.isSearchUserError) || false;
  const isSearchUserLoading =
    useSelector((state) => state.auth.isSearchUserLoading) || false;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (inProgress === InteractionStatus.None && !isAuthenticated) {
          return <Redirect to="/login" />;
          } else if (employeeInfo.adUpn) {
            return <Component {...props} />;
        } else if (isSearchUserError) {
          return <ErrorPage />;
        } else if (isSearchUserLoading) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                flexDirection: "column"
              }}
            >
              <CircularProgress color="primary" size={30} />
              <h2 className="mt-10">Searching User</h2>
            </div>
          );
        } else {
          return null;
        }
      }}
    />
  );
};

export default PrivateRoute;
