import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../actions/actions";

const TabManagerGeneric = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const {tabId, tabId2 = ''} = useParams();
  const [tabs, setTabs] = useState(props.tabsData);
  const changeActiveTab = (e, tabId, tabId2='') => {
    e.preventDefault();
    history.push({
      pathname: '/' + props.mainPathName + "/" + tabId,
    });
  };

  useEffect(() => {
    setTabs((prevState) => 
      prevState.map((tab) => {
        const updatedTab = { ...tab };
        updatedTab.isActive = tab.tabId === tabId
        return updatedTab;
      })
    );
  }, [tabId, tabId2]);

  const Component = tabs?.find((tab) => tab.isActive === true)?.component;
  const TitleActive = tabs?.find((tab) => tab.isActive === true);
  dispatch(setActiveTab(TitleActive?.tabTitle));

  return (
    <>
      <div className="tab-manager">
        <div className="tab-headers">
          {tabs.map((tab) => (
            <div
              className={`tab-header ${tab.isActive && "active-tab"}`}
              onClick={(e) => {
                changeActiveTab(e, tab.tabId);
              }}
              key={tab.tabTitle}
            >
              <div>{tab.tabTitle}</div>
              <Box
                bgcolor="primary.main"
                className={`${tab.isActive && "active-tab-indicator"}`}
              />
            </div>
          ))}
        </div>
        <div className="tab-content">
          {Component && <Component />}
        </div>
      </div>
    </>
  );
};

export default TabManagerGeneric;
