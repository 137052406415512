import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { getDate, toFixedVal, getStoreChecker, roundValue } from "../../utils/utils";
import { useLazyQuery } from "@apollo/client";
import {
  GET_MERCH_CLASS_SKU_METRICS,
  GET_DEFAULT_LOCATION,
} from "../../graphql/queries";
import { connect } from "react-redux";
import MerchSkuRow from "./MerchSkuRow";
import { CircularProgress } from "@material-ui/core";
import {
  ALL_DEPARTMENTS,
  DATA_FETCH_ERROR,
  ASCENDING,
  DESCENDING,
  ONLOAN_INVALID_STOREID_ERROR,
  NO_CONNECTION_ERROR,
} from "../../common/constants";
import ErrorMessage from "../ErrorMessage";
import SortableMetrics from "./SortableMetrics";
import useNetwork from "../UseNetwork";
import Typography from "@material-ui/core/Typography";

const MerchClassDataRow = ({
  merchClassTitle,
  sales,
  units,
  compPercent,
  expandSkuData,
  selectedSkuMerchNumber,
  selectedSkuMerchClassNumber,
  selectedMerchOption,
  selectedMerchClass,
  employeeInfo = {},
  dateMatrix,
}) => {
  const [skuSortOptions, setSkuSortOptions] = React.useState([]);
  const [sortBy, setSortBy] = React.useState("primarySales");
  const [sortType, setSortType] = React.useState("desc");
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  const selectedMerchValue = selectedMerchOption;
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const networkStatus = useNetwork();
  const userEmail = employeeInfo.adUpn;
  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getMerchClassSkuSummary,
    {
      data: { classLevelSummary: { classSkuMatrix } = {} } = {},
      error = {},
      loading,
    },
  ] = useLazyQuery(GET_MERCH_CLASS_SKU_METRICS, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix &&
      selectedSkuMerchNumber &&
      selectedSkuMerchClassNumber
    ) {
      getMerchClassSkuSummary({
        variables: {
          request: {
            email: userEmail,
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            departmentNumber: selectedSkuMerchNumber,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            classNumber: selectedSkuMerchClassNumber,
            pageableSort: {
              sort: sortType,
              orderBy: sortBy,
            },
          },
        },
      });
    }
  }, [
    userEmail,
    selectedLocation,
    dateMatrix,
    getMerchClassSkuSummary,
    selectedSkuMerchNumber,
    selectedSkuMerchClassNumber,
    sortType,
    sortBy,
  ]);

  const {
    firstTitle = undefined,
    secondTitle = undefined,
    classSales = [],
  } = classSkuMatrix || {};

  useEffect(() => {
    if (classSkuMatrix) {
      let skuSortOptionJson = [
        {
          label: `Sales $`,
          isChecked: true,
          value: "primarySales",
        },
        {
          label: `Units`,
          value: "primaryUnits",
          isChecked: false,
        },
        {
          label: `${secondTitle} Sales $`,
          value: "secondarySales",
          isChecked: false,
        },
        {
          label: `${secondTitle} Units`,
          value: "secondaryUnits",
          isChecked: false,
        },
        {
          label: ASCENDING,
          value: "asc",
          isChecked: false,
        },
        {
          label: DESCENDING,
          value: "desc",
          isChecked: true,
        },
      ];
      setSkuSortOptions(skuSortOptionJson);
    }
  }, [classSkuMatrix, firstTitle, secondTitle, setSkuSortOptions]);

  const onApplyClickHandler = (selectedOptions) => {
    if (selectedOptions[0].isChecked) {
      setSortBy(selectedOptions[0].value);
    }
    if (selectedOptions[1].isChecked) {
      setSortType(selectedOptions[1].value);
    }
  };

  const updateMenuItems = (updateMenuItems) => {
    setSkuSortOptions(updateMenuItems);
  };
  let borderRowClass = "";
  if (selectedMerchValue?.label !== ALL_DEPARTMENTS) {
    borderRowClass = "top-border-row";
  }

  return (
    <>
      <div className="flex row">
        <div
          className="merch-title"
          style={{
            color:
              selectedMerchValue?.label === ALL_DEPARTMENTS ? "#0066ff" : null,
          }}
        >
          {merchClassTitle}
        </div>
      </div>
      <div className="flex row">
        <div className="b-1-3 merch-sales-container">
          <div className="merch-sales-value">{roundValue(sales,"$")}</div>
          <div className="merch-sales-label">Sales $</div>
        </div>
        <div className="b-1-3 merch-sales-container">
          <div className="merch-sales-value">{roundValue(units)}</div>
          <div className="merch-sales-label">Units</div>
        </div>
        <div className="b-1-3 merch-sales-container">
          <div className="merch-sales-value">
            {toFixedVal(compPercent, "%", "end", 1)}
          </div>
          <div className="merch-sales-label">Comp%</div>
        </div>
      </div>
      <div className={`border-row ${borderRowClass}`} />

      {expandSkuData && (
        <div className="sku-table-container class-sku-table-container">
          {loading && expandSkuData && (
            <div className="merch-detail-loader">
              <CircularProgress color="primary" size={30} />
            </div>
          )}
          {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
          {!loading && (
            <>
              {error.message ? (
                <ErrorMessage
                  statusCode={
                    error?.networkError?.statusCode ||
                    locationError?.networkError?.statusCode
                  }
                  message={DATA_FETCH_ERROR}
                />
              ) : null}
              {activeStorechecker === false && selectedStoreId ? (
                <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
              ) : null}

              {selectedSkuMerchClassNumber &&
                classSales?.length > 0 &&
                classSkuMatrix &&
                skuSortOptions.length > 0 && (
                  <>
                    <div className="merch-sticky-container">
                      <SortableMetrics
                        onApplyClickHandler={onApplyClickHandler}
                        sortOptions={skuSortOptions}
                        updateMenuItems={updateMenuItems}
                      />
                      <div className="merch-sku-label-container">
                        <div className="merch-sku-label">Sales $</div>
                        <div className="merch-sku-label">Units</div>
                        <div className="merch-sku-label">
                          {secondTitle} Sales $
                        </div>
                        <div className="merch-sku-label">
                          {secondTitle} Units
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {!classSales?.length > 0 ? (
                <div className="merch-noData-card">
                  <div className="no-data-row">
                    <Typography component="h1" variant="h1" className="label">
                      There is no data available for current selection. Please
                      try a new selection{" "}
                    </Typography>
                  </div>
                </div>
              ) : (
                selectedSkuMerchClassNumber &&
                classSales?.length > 0 &&
                classSales.map((classSales, index) => {
                  const {
                    itemNumber,
                    primaryUnit,
                    primarySales,
                    secondaryUnit,
                    secondarySales,
                    itemName,
                  } = classSales;

                  return (
                    <MerchSkuRow
                      key={`${itemNumber}-${index}`}
                      firstTitle={firstTitle}
                      secondTitle={secondTitle}
                      sales={classSales}
                      itemNumber={itemNumber}
                      primaryUnit={primaryUnit}
                      primarySales={primarySales}
                      secondaryUnit={secondaryUnit}
                      secondarySales={secondarySales}
                      itemName={itemName}
                    />
                  );
                })
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
MerchClassDataRow.defaultProps = {
  merchClassTitle: "",
  sales: 0,
  units: 0,
  compPercent: 0,
};

MerchClassDataRow.propTypes = {
  merchClassTitle: PropTypes.string,
  sales: PropTypes.any,
  units: PropTypes.any,
  compPercent: PropTypes.any,
};
const mapStateToProps = (state) => ({
  employeeInfo: state?.auth?.employeeInfo,
  dateMatrix: state?.salesSummary?.currentDateMatrix.value,
});

export default connect(mapStateToProps, undefined)(MerchClassDataRow);
