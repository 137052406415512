import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import {
  GET_DEFAULT_LOCATION,
  GET_MERCH_DATA,
  GET_INVENTORY_CONTROL_MERCH,
} from "../../graphql/queries";
import SelectDropdown from "../SelectDropdown";

import {
  ALL_DEPARTMENTS,
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
  NO_CONNECTION_ERROR,
} from "../../common/constants";
import Typography from "@material-ui/core/Typography";

import "./styles.scss";
import { connect } from "react-redux";
import { getDate, toTitleCase, getStoreChecker } from "../../utils/utils";
import ErrorMessage from "../ErrorMessage";
import InventoryControlZeroSalesDataRow from "./InventoryControlZeroSalesDataRow";
import useNetwork from "../UseNetwork";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.6rem",
    color: state.isSelected ? "#FFFFFF" : "#353535",
    padding: 7,
  }),
};

const InventoryMerchDetailTab = ({ employeeInfo = {}, dateMatrix }) => {
  const networkStatus = useNetwork();
  const [storeMerchs, setStoreMerchs] = React.useState([]);
  const [selectedMerch, setSelectedMerch] = React.useState({});
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  const userEmail = employeeInfo?.adUpn;
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);

  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getMerchSummary,
    {
      data: { departmentSummary: { departmentSalesReport = [] } = {} } = {},
      error = {},
      loading,
    },
  ] = useLazyQuery(GET_MERCH_DATA, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (departmentSalesReport && departmentSalesReport?.length > 0) {
      let newOptions = [{ label: ALL_DEPARTMENTS, value: ALL_DEPARTMENTS }];
      if (departmentSalesReport?.length > 0) {
        departmentSalesReport.forEach((obj) => {
          if (obj["department"]) {
            let option = {};
            option.label =
              obj["department"]["deptName"] === "HBA"
                ? obj["department"]["deptNumber"] +
                  ": " +
                  obj["department"]["deptName"]
                : obj["department"]["deptNumber"] +
                  ": " +
                  toTitleCase(obj["department"]["deptName"]);
            option.value = obj["department"]["deptNumber"];
            newOptions.push(option);
          }
        });
        newOptions = newOptions.sort((a, b) => a.value - b.value);
        setStoreMerchs(newOptions);
        setSelectedMerch(newOptions[0]);
      }
    }
  }, [departmentSalesReport]);

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix
    ) {
      getMerchSummary({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
          },
        },
      });
    }
  }, [userEmail, selectedLocation, dateMatrix, getMerchSummary]);

  const handleOnMerchChange = (option) => {
    if (![ALL_DEPARTMENTS].includes(option.label)) {
    }
    setSelectedMerch(option);
  };

  const [
    getInventoryMerch,
    {
      data: {
        inventoryControlSkuSummary: {
          inventoryControlZeroSellingSkuReport: icZeroSellingSkuReport,
        } = {},
      } = {},
      error: inventoryMerchError = {},
      loading: inventoryMerchloading,
    },
  ] = useLazyQuery(GET_INVENTORY_CONTROL_MERCH, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix &&
      selectedMerch
    ) {
      getInventoryMerch({
        variables: {
          request:
            selectedMerch.value === "All Departments"
              ? {
                  email: userEmail,
                  toDate: getDate("YESTERDAY", ""),
                  matrixType: dateMatrix,
                  location: {
                    primaryLocationNumber: selectedLocation?.locationNo,
                    primaryLocationName: selectedLocation?.locationName,
                  },
                }
              : {
                  email: userEmail,
                  toDate: getDate("YESTERDAY", ""),
                  matrixType: dateMatrix,
                  departmentNumber: selectedMerch.value,
                  location: {
                    primaryLocationNumber: selectedLocation?.locationNo,
                    primaryLocationName: selectedLocation?.locationName,
                  },
                },
        },
      });
    }
  }, [
    userEmail,
    selectedLocation,
    dateMatrix,
    getInventoryMerch,
    selectedMerch,
  ]);

  return (
    <div className="ic-merch-detail-tab">
      {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
      {loading && (
        <div className="ic-merch-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!loading && (
        <>
          {error.message ? (
            <ErrorMessage
              statusCode={
                error?.networkError?.statusCode ||
                locationError?.networkError?.statusCode
              }
              message={DATA_FETCH_ERROR}
            />
          ) : error.mesage ? (
            <ErrorMessage
              statusCode={
                error?.networkError?.statusCode ||
                locationError?.networkError?.statusCode
              }
              message={error.message}
            />
          ) : inventoryMerchError.message ? (
            <ErrorMessage
              statusCode={inventoryMerchError?.status}
              message={inventoryMerchError.message}
            />
          ) : null}
          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}
          {!departmentSalesReport?.length > 0 ? (
            <div className="ic-merch-noData-card">
              <div className="no-data-row">
                <Typography component="h1" variant="h1" className="label">
                  There is no data available for this timeframe. Please go back
                  and try a new selection{" "}
                </Typography>
              </div>
            </div>
          ) : (
            <>
              <div className="dropdown-container">
                <div className="dropdown-column">
                  <SelectDropdown
                    className="dropdown-row"
                    styles={customStyles}
                    onChange={handleOnMerchChange}
                    options={storeMerchs}
                    value={selectedMerch}
                    isSearchable={false}
                  />
                </div>
              </div>

              <div className="table-container">
                <div className="zero-sales-label-container">
                  <div className="ic-merch-sales-label">
                    Sales U {dateMatrix}
                  </div>
                  <div className="ic-merch-sales-label">Sales U 3Wks</div>
                  <div className="ic-merch-sales-label">Net U OH</div>
                </div>
                <div className="border-row top-border-row"></div>
                <div
                  className={
                    icZeroSellingSkuReport?.length > 0
                      ? `table-content`
                      : `table-content-nodata`
                  }
                >
                  {inventoryMerchloading && (
                    <div className="ic-merch-detail-loader">
                      <CircularProgress color="primary" size={30} />
                    </div>
                  )}

                  {!inventoryMerchloading &&
                  icZeroSellingSkuReport?.length > 0 ? (
                    <>
                      {icZeroSellingSkuReport &&
                        icZeroSellingSkuReport?.length > 0 &&
                        icZeroSellingSkuReport.map(
                          (icZeroSellingSkuReport, index) => {
                            const {
                              itemNumber,
                              itemName,
                              netOHInvUnitCount,
                              salesUnitCount,
                              threeWeekSalesUnitCount,
                            } = icZeroSellingSkuReport;

                            return (
                              <InventoryControlZeroSalesDataRow
                                key={`${itemNumber}-${index}`}
                                icitemNumber={itemNumber}
                                icitemName={itemName}
                                icnetOHInvUnitCount={netOHInvUnitCount}
                                icsalesUnitCount={salesUnitCount}
                                icthreeWeekSalesUnitCount={
                                  threeWeekSalesUnitCount
                                }
                                icZeroSellingSkuReport={icZeroSellingSkuReport}
                              />
                            );
                          }
                        )}
                    </>
                  ) : (
                    !inventoryMerchloading && (
                      <div className="ic-merch-noData-card">
                        <div className="no-data-row">
                          <Typography
                            component="h1"
                            variant="h1"
                            className="label"
                          >
                            There is no data available for this timeframe.
                            Please go back and try a new selection{" "}
                          </Typography>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
  dateMatrix: state.salesSummary.currentDateMatrix.value,
});

export default connect(mapStateToProps, undefined)(InventoryMerchDetailTab);
