import { TableCell } from "@material-ui/core";
import React from "react";

const InventoryDataItem = ({ itemAmount, itemTitle }) => {
  return (
    <TableCell
      data-testid="summary-data-item"
      className="summary-data-item-container"
      style={{padding:"2px", border:"none",top:"-2px" }}
    >
      <div className="summary-data-title">{itemTitle}</div>
      <div className="summary-data">{itemAmount}</div>
    </TableCell>
  );
};

export default InventoryDataItem;
