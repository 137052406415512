import React, { useState } from "react";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Box from "@material-ui/core/Box";
import Check from "@material-ui/icons/Check";

const SkuSortableMetrice = ({
  dropdownData,
  sortOptionsLabelData,
  onApplyClickHandler,
}) => {
  const [exapnded, setExpanded] = useState(false);
  const [menuItems, setMenuItems] = useState(dropdownData || []);

  const toggleSortMenu = () => {
    setExpanded((prevIsMwnuOpen) => !prevIsMwnuOpen);
  };

  const toggleMenuItem = (selectedMenuItem, index) => () => {
    const updatedMenuItems = menuItems.map((mi) => {
      if (mi.label !== selectedMenuItem.label) {
        if (
          (sortOptionsLabelData.includes(selectedMenuItem.label) &&
            !sortOptionsLabelData.includes(mi.label)) ||
          (!sortOptionsLabelData.includes(selectedMenuItem.label) &&
          sortOptionsLabelData.includes(mi.label))
        ) {
          return mi;
        }
        return { ...mi, isChecked: false };
      }
      return { ...mi, isChecked: true };
    });
    setMenuItems(updatedMenuItems);
  };

  const applyFilter = () => {
    setExpanded(false);
    const checkedMenuItems = menuItems.filter((mi) => mi.isChecked);
    onApplyClickHandler(checkedMenuItems);
  };

  return (
    <div className="generic-sort-container">
      <div className="generic-sort-closed" onClick={toggleSortMenu}>
        Sort
        <KeyboardArrowDown
          color="primary"
          fontSize="large"
          variant="filled"
        />
      </div>
      {exapnded && (
        <div className="generic-sort-container-expanded">
          <div className="menu-item sort-by-header px-20 py-5">
            <span>Sort By</span>
          </div>
          {menuItems.map((data, index) => (
            <div
              className={`menu-item px-20 py-10 ${
                data.label === `Ascending` ? "ascending-menu-item" : ""
              }`}
              key={index}
              onClick={toggleMenuItem(data, index)}
            >
              <span>{data.label}</span>
              {data.isChecked && (
                <Check color="primary" fontSize="medium" variant="filled" />
              )}
            </div>
          ))}
          <Box
            color="primary.main"
            className="menu-item apply-button px-20 py-5"
            onClick={applyFilter}
          >
            <span>Apply</span>
          </Box>
        </div>
      )}
    </div>
  );
};

export default SkuSortableMetrice;
