import React from "react";
import { toTitleCase } from "../../utils/utils";

const ICAdjustmentSkuRow = ({
  title,
  skuNumber,
  unitQuantity,
  amount
}) => {
  return (
    <>
      <div className="IC-adjustment-row-container">
        <div className="IC-adjustment-Subtitle">
          {toTitleCase(`${title} ${skuNumber}`)}
        </div>
      </div>
      <div className="flex row">
        <div className="b-1-2 IC-adjustment-sku-container">
          <div className="IC-adjustment-sku-value">{unitQuantity}</div>
          <div className="IC-adjustment-sku-label">Units</div>
        </div>
        <div className="b-1-2 IC-adjustment-sku-container">
          <div className="IC-adjustment-sku-value">{amount}</div>
          <div className="IC-adjustment-sku-label">$</div>
        </div>
      </div>
      <div className={`longsku-bottom-border`} />
    </>
  );
};

export default ICAdjustmentSkuRow;
