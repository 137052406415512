import React from "react";

const SummaryDataItem = ({ itemAmount, itemTitle }) => {
  return (
    <div
      data-testid="summary-data-item"
      className="summary-data-item-container"
    >
      <div className="summary-data-title">{itemTitle}</div>
      <div className="summary-data">{itemAmount}</div>
    </div>
  );
};

export default SummaryDataItem;
