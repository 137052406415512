export const DATA_FETCH_ERROR =
  "Something went wrong. We encountered an error while fetching data. Please try again in a few minutes.";
export const NO_CONNECTION_ERROR =
  "No internet Connection. Make sure that Wi-Fi or mobile data is turned on and try again.";
export const ONLOAN_INVALID_STOREID_ERROR =
  "I’m sorry, we cannot connect you at this time. Please contact your administrator.";
export const ALL_DEPARTMENTS = "All Departments";
export const EAR_PIERCING = "Ear Piercing";
export const BALOONS = "Balloons";
export const ALL_CLASSES = "All Classes";
export const STORE_TOTAL = "Store Total";
export const ASCENDING = "Ascending";
export const DESCENDING = "Descending";
export const TOTAL = "Total";
export const IC_REPLACE = "Replace";
export const ADJUSTMENTS = "Adjustments";
export const DAMAGES = "Damage";
export const MARKED_OUT_OF_STOCK = "Marked Out of Stock";
export const KNOWN_THEFT = "Known Theft";
export const SHIPMENT = "Shipment";
export const CANDY = "Candy";
export const SHORT_MARKED_OUT_OF_STOCK = "MOS";
export const LABOR = "Labor";
export const TREND = "Trend";
export const SALES = "Sales";
export const SERVICES = "Services";
export const DRIVERS = "Drivers";
export const INVENTORY_CONTROL = "Inventory Control";
export const MERCH = "Merch";
export const ZERO_SALES = "Zero Sales";
export const TAB = "Tab";
export const DSH = "-";
export const OMNI_CHANNEL=`Omni Channel`;
export const BOPIS = "BOPIS";
export const SFS = "SFS";

export const ALL_REASON_CODE="All Reason Codes";
export const TOTAL_ADJUSTMENTS="TOTAL ADJUSTMENTS";
export const BROKEN = "Broken";
export const NO_PACKAGING_UNSELLABLE = "No packaging/Unsellable";
export const CUSTOMER_RETURN = "Customer Return";
export const ECOM_RETURN = "Ecom Return";
export const FLOODING_PESTS_NATURAL_DISASTER = "Flooding/Pests/Natural Disaster";
export const WOWTOWN_DIRECTED_MOS = "WowTown Directed MOS";
export const IN_STORE_EVENT_AND_PRIZES = "In Store Event and Prizes";
export const TESTERS_AND_DISPLAYS = "Testers and Displays";
export const CUSTOMER_ACCOMMODATION = "Customer Accommodation";
export const WOWCREW_SNACKS_AND_RECOGNITION = "WowCrew Snacks and Recognition";
export const EXPIRED_PRODUCT = "Expired Product";
export const DEFECTIVE_PRODUCT = "Defective Product";
export const FOUND_PACKAGING = "Found Packaging";
export const OBSERVED_THEFT = "Observed Theft";
export const RECEIVED_DAMAGED = "Received Damaged";
export const RECEIVED_EXPIRED = "Received Expired";
export const CANDY_SHORT_SHIPPED = "Candy Short Shipped";
export const CANDY_OVER_SHIPPED = "Candy Over Shipped";

export const YESTERDAY = `YESTERDAY`;
export const WTD = `WTD`;
export const LCW = `LCW`;
export const MTD = `MTD`;
export const QTD = `QTD`;
export const YTD = `YTD`;

export const WEEK_TO_DATE = `Week To Date`;
export const LAST_COMPLETED_WEEK = `Last Completed Week`;
export const MONTH_TO_DATE = `Month To Date`;
export const QUARTER_TO_DATE = `Quarter To Date`;
export const YEAR_TO_DATE = `Year to Date`;

export const ALL_ADJUSTMENTS = "All Adj. codes";
export const REPLACE = "01 - Replace";
export const ADJ_PLUS = "02 -  O/H Adj. - Plus";
export const ADJ_MINUS = "03 -  O/H Adj. - Minus";
export const CUSTOMER_SERVICE = "10 - Customer Service";
export const SHORT_SHIPPED_MINUS = "71 - DTS Short Shipped - Minus";
export const OVER_SHIPPED_PLUS = "74 - DTS OVER SHIPPED - Plus";

export const UNITS_ASCENDING = `Units ${ASCENDING}`;
export const UNITS_DESCENDING = `Units ${DESCENDING}`;
export const AMOUNT_ASCENDING = `$ ${ASCENDING}`;
export const AMOUNT_DESCENDING = `$ ${DESCENDING}`;
export const ASCENDING_VALUE = `asc`;
export const DESCENDING_VALUE = `desc`;
export const ORDER_BY_UNITS = `units`;
export const ORDER_BY_AMOUNT = `amount`;

//for Inventory Control Drivers Total Adjustments Screen
export const INVENTORY_CONTROL_TOTAL_ADJUSTMENTS_SORT_DROPDOWN_ARRAY = [
  UNITS_ASCENDING,
  UNITS_DESCENDING,
  AMOUNT_ASCENDING,
  AMOUNT_DESCENDING,
];
export const INVENTORY_CONTROL_TOTAL_ADJUSTMENTS_SORT_OPTIONS = [
  {
    label: UNITS_ASCENDING,
    value: ASCENDING_VALUE,
    isChecked: false,
    orderBy: ORDER_BY_UNITS,
  },
  {
    label: AMOUNT_ASCENDING,
    value: ASCENDING_VALUE,
    isChecked: false,
    orderBy: ORDER_BY_AMOUNT,
  },
  {
    label: UNITS_DESCENDING,
    value: DESCENDING_VALUE,
    isChecked: true,
    orderBy: ORDER_BY_UNITS,
  },
  {
    label: AMOUNT_DESCENDING,
    value: DESCENDING_VALUE,
    isChecked: false,
    orderBy: ORDER_BY_AMOUNT,
  },
];

export const ADJUSTMENT_OPTIONS = [
  { label: ALL_ADJUSTMENTS, value: ALL_ADJUSTMENTS },
  { label: REPLACE, value: REPLACE },
  { label: ADJ_PLUS, value: STORE_TOTAL },
  { label: ADJ_MINUS, value: ADJ_MINUS },
  { label: CUSTOMER_SERVICE, value: CUSTOMER_SERVICE },
  { label: SHORT_SHIPPED_MINUS, value: SHORT_SHIPPED_MINUS },
  { label: OVER_SHIPPED_PLUS, value: OVER_SHIPPED_PLUS },
];


