import React from "react";

const SkuRow = ({
  title,
  skuNumber,
  unitQuantity,
  amount
}) => {
  return (
    <>
      <div>
        <div className="longsku-driver-title">
          <span>{title} &nbsp;{skuNumber}</span>
        </div>
      </div>
      <div className="longsku-container">
        <div className="driver-sales-container">
          <div className="driver-sales-value">{unitQuantity}</div>
          <div className="driver-sales-label">Units</div>
        </div>
        <div className="driver-sales-container">
          <div className="driver-sales-value">{amount}</div>
          <div className="driver-sales-label">$</div>
        </div>
      </div>
      <div className={`longsku-bottom-border`} />
    </>
  );
};

export default SkuRow;
