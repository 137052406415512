import { FETCH_USER_DETAILS, SEARCH_USER } from "./types";

export const fetchUserProfile = (userData) => {
  const url = "/users/info";

  const payload = {
    action: FETCH_USER_DETAILS,
    method: "POST",
    redirect: "follow",
    data: userData,
    url,
  };
  return { type: "API_INVOCATION", payload };
};

export const searchUser = (userData) => {
  const url = "/users/search";
  const payload = {
    action: SEARCH_USER,
    method: "POST",
    data: userData,
    url,
  };

  return { type: "API_INVOCATION", payload };
};
