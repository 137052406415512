import React, { useState, useEffect } from "react";
import "./styles.scss";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, Divider } from "@material-ui/core";
import { GET_SALES_TREND, GET_DEFAULT_LOCATION } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { getDate, getStoreChecker, toTitleCase } from "../../utils/utils";
import { connect } from "react-redux";
import { Bar, Line } from "react-chartjs-2";
import ErrorMessage from "../ErrorMessage";
import {
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
} from "../../common/constants";

function useMediaQuery(query) {
  const getMatches = (query) => {
    if (typeof window !== "undefined") {
      if (window.matchMedia(query) && window.matchMedia(query).matches)
        return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  useEffect(() => {
    const handleChange = () => {
      setMatches(getMatches(query));
    };
    const matchMedia = window.matchMedia(query);
    handleChange();
    if (matchMedia && matchMedia.addEventListener)
      matchMedia.addEventListener("change", handleChange);
    return () => {
      if (matchMedia && matchMedia.removeEventListener)
        matchMedia.removeEventListener("change", handleChange);
    };
  }, [query]);

  return matches;
}

const SalesTrend = ({ employeeInfo = {}, dateMatrix }) => {
  const [selectedLocation, setSelectedLocation] = useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const matches = useMediaQuery("(max-width: 767px)");
  const [varianceToPlanXaxisData, setvarianceToPlanXaxisData] = useState([]);
  const [varianceToPlanYaxisData, setvarianceToPlanYaxisData] = useState([]);
  const [unitsPerTransactionXaxisData, setunitsPerTransactionXaxisData] =
    useState([]);
  const [unitsPerTransactionYaxisData, setunitsPerTransactionYaxisData] =
    useState([]);
  const [avgDollarSaleXaxis, setavgDollarSaleXaxis] = useState([]);
  const [avgDollarSaleYaxis, setavgDollarSaleYaxis] = useState([]);

  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  const userEmail = employeeInfo.adUpn;

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getSalesTrendDetails,
    {
      data: { salesSummaryV2: { salesTrend = {} } = {} } = {},
      loading,
      error = {},
    },
  ] = useLazyQuery(GET_SALES_TREND, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  let trendValues = Object.entries(salesTrend).map((entry) => {
    let key = entry[0];
    return key;
  });

  const { varianceToPlanAmount, unitsPerTransaction, averageDollarSale } =
    salesTrend;

  useEffect(() => {
    let variancePlanXaxis = [];
    let variancePlanYaxis = [];
    let unitsPerTransXaxis = [];
    let unitsPerTransYaxis = [];
    let avgDollarXaxis = [];
    let avgDollarYaxis = [];

    if (varianceToPlanAmount) {
      for (let i = 0; i < varianceToPlanAmount?.keyList.length; i++) {
        variancePlanXaxis.push(varianceToPlanAmount?.keyList[i].key);
        variancePlanYaxis.push(varianceToPlanAmount?.keyList[i].value);
      }
    }

    if (unitsPerTransaction) {
      for (let i = 0; i < unitsPerTransaction?.keyList.length; i++) {
        unitsPerTransXaxis.push(unitsPerTransaction?.keyList[i].key);
        unitsPerTransYaxis.push(unitsPerTransaction?.keyList[i].value);
      }
    }

    if (averageDollarSale) {
      if (unitsPerTransaction) {
        for (let i = 0; i < unitsPerTransaction?.keyList.length; i++) {
          avgDollarXaxis.push(averageDollarSale?.keyList[i].key);
          avgDollarYaxis.push(averageDollarSale?.keyList[i].value);
        }
      }
    }

    setvarianceToPlanXaxisData(variancePlanXaxis);
    variancePlanYaxis = variancePlanYaxis.map(function (each_element) {
      return Math.round(each_element);
    });
    setvarianceToPlanYaxisData(variancePlanYaxis);
    setunitsPerTransactionXaxisData(unitsPerTransXaxis);
    setunitsPerTransactionYaxisData(unitsPerTransYaxis);
    setavgDollarSaleXaxis(avgDollarXaxis);
    setavgDollarSaleYaxis(avgDollarYaxis);
  }, [
    varianceToPlanAmount,
    unitsPerTransaction,
    averageDollarSale,
    setvarianceToPlanXaxisData,
    setvarianceToPlanYaxisData,
    setunitsPerTransactionXaxisData,
    setunitsPerTransactionYaxisData,
    setavgDollarSaleXaxis,
    setavgDollarSaleYaxis,
  ]);

  let updatedTrendValues = [];
  for (let index = 1; index < trendValues.length; index++) {
    const result = trendValues[index].replace(/([A-Z])/g, " $1");
    const finalResult = toTitleCase(result);
    updatedTrendValues.push(finalResult);
  }

  let modifiedVarianceToPlanAmount = updatedTrendValues[0]?.replace(
    " Amount",
    " ($)"
  );
  updatedTrendValues[0] = modifiedVarianceToPlanAmount;
  useEffect(() => {
    if (
      userEmail &&
      dateMatrix &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA"
    ) {
      setvarianceToPlanXaxisData([]);
      setvarianceToPlanYaxisData([]);
      setunitsPerTransactionXaxisData([]);
      setunitsPerTransactionYaxisData([]);
      setavgDollarSaleXaxis([]);
      setavgDollarSaleYaxis([]);
      getSalesTrendDetails({
        variables: {
          request: {
            email: userEmail,
            toDate: getDate("YESTERDAY", ""),
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            matrixType: dateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    dateMatrix,
    getSalesTrendDetails,
    selectedLocation,
    setvarianceToPlanXaxisData,
    setvarianceToPlanYaxisData,
    setunitsPerTransactionXaxisData,
    setunitsPerTransactionYaxisData,
    setavgDollarSaleXaxis,
    setavgDollarSaleYaxis,
  ]);

  const barChartoptions = {
    indexAxis: "x",
    layout: {
      padding: [0, 10],
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.formattedValue !== null) {
              if (context.formattedValue.substring(0, 1) === "-") {
                label += `($${context.formattedValue.substring(1)})`;
              } else {
                label += `$${context.formattedValue}`;
              }
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: (context) => {
            const zeroLine = context.tick.value;
            const barColor = zeroLine === 0 ? "#000" : "#dcdcdc";
            return barColor;
          },
        },
        ticks: {
          autoSkip: false,
          callback: function (value) {
            if (value >= 0) return "$" + value.toLocaleString("en");
            else return "( $" + Math.abs(value).toLocaleString("en") + ")";
          },
        },
      },
      x: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  const varToPlanHoursData = {
    labels: varianceToPlanXaxisData,
    datasets: [
      {
        axis: "y",
        data: varianceToPlanYaxisData,
        borderColor: "#3333ff",
        barPercentage: 1,
        categoryPercentage: 0.5,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",

        fill: "start",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 150);
          gradient.addColorStop(0, "#367bff");
          gradient.addColorStop(0.5, "#669cff");
          gradient.addColorStop(1, "#cbddf6");

          return gradient;
        },
      },
    ],
  };

  const overtimeData = {
    labels: unitsPerTransactionXaxisData,
    datasets: [
      {
        data: unitsPerTransactionYaxisData,
        borderColor: "#f68023",
        fill: "start",
        pointRadius: 5,
        pointHitRadius: 5,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = matches
            ? ctx.createLinearGradient(0, 175, 0, 0)
            : ctx.createLinearGradient(0, 250, 0, 0);
          gradient.addColorStop(1, "#ffa032");
          gradient.addColorStop("0.8", "#ffa030");
          gradient.addColorStop("0.6", "#ff9f39");
          gradient.addColorStop("0.5", "#ffc699");
          gradient.addColorStop("0.3", "#ffdfc6");
          gradient.addColorStop(0, "#ffffff");
          return gradient;
        },
      },
    ],
  };

  const avgDollarSaleData = {
    labels: avgDollarSaleXaxis,
    datasets: [
      {
        data: avgDollarSaleYaxis,
        borderColor: "#5823F6",
        fill: "start",
        pointRadius: 5,
        pointHitRadius: 5,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = matches
            ? ctx.createLinearGradient(0, 175, 0, 0)
            : ctx.createLinearGradient(0, 250, 0, 0);
          gradient.addColorStop(1, "#6332FF");
          gradient.addColorStop("0.8", "#6D4AFF");
          gradient.addColorStop("0.6", "#917eff");
          gradient.addColorStop("0.5", "#c1b7FF");
          gradient.addColorStop("0.3", "#eeecff");
          gradient.addColorStop("0.2", "#efedff");
          gradient.addColorStop(0, "#ffffff");
          return gradient;
        },
      },
    ],
  };

  const lineChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.formattedValue !== null) {
              label += Number(context.formattedValue).toFixed(1);
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          callback: function (value) {
            return (Math.round(value * 100) / 100).toFixed(1);
          },
        },
      },
      x: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  const avgDollarlineChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.formattedValue !== null) {
              label += `$${Number(context.formattedValue).toFixed(2)}`;
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          callback: function (value) {
            return "$" + Math.round(value * 100) / 100;
          },
        },
      },
      x: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  return (
    <div data-testid="sales-trend" className="sales-trend-container">
      {loading && (
        <div className="sales-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!loading && (
        <>
          {locationError.message || error.message ? (
            <ErrorMessage
              statusCode={
                locationError?.networkError?.statusCode ||
                error?.networkError?.statusCode
              }
              message={
                locationError?.networkError?.statusCode ||
                error?.networkError?.statusCode
                  ? DATA_FETCH_ERROR
                  : error.message
              }
            />
          ) : null}
          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}
          <div className="splitScreen">
            <div className="topPane">
              <div className="innerTopPane">
                <div className="titleTopPane">
                  <div className="title-row-top">
                    <Typography component="span" className="sales-trend-titles">
                      {updatedTrendValues[0]}
                    </Typography>
                  </div>
                </div>
                <div className="contentTopPane">
                  <Bar
                    style={{ border: "1px solid rgba(255, 255, 255)" }}
                    type="bar"
                    width="100%"
                    options={barChartoptions}
                    data={varToPlanHoursData}
                    borderwidth={2}
                    pointbordercolor={"#fff"}
                    pointbackgroundcolor={"rgba(173, 53, 186, 0.1)"}
                  />
                </div>
              </div>
            </div>

            <Divider variant="middle" className="divider" />

            <div className="middlePane">
              <div className="innerMiddlePane">
                <div className="titleMiddlePane">
                  <div className="title-row-middle">
                    <Typography component="span" className="sales-trend-titles">
                      {updatedTrendValues[1]}
                    </Typography>
                  </div>
                </div>
                <div className="contentMiddlePane">
                  <Line data={overtimeData} options={lineChartoptions} />
                </div>
              </div>
            </div>

            <Divider variant="middle" className="divider" />

            <div className="bottomPane">
              <div className="innerBottomPane">
                <div className="titleBottomPane">
                  <div className="title-row-bottom">
                    <Typography component="span" className="sales-trend-titles">
                      {updatedTrendValues[2]}
                    </Typography>
                  </div>
                  <div className="contentBottomPane">
                    <Line
                      data={avgDollarSaleData}
                      options={avgDollarlineChartoptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeInfo: state.auth.employeeInfo,
    dateMatrix: state?.salesSummary?.currentDateMatrix?.value,
  };
};

export default connect(mapStateToProps, undefined)(SalesTrend);
