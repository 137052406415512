import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  alignItemsAndJustifyContent: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  btn: {
    backgroundColor: "rgba(0, 102, 255, 1)",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    marginTop: 10,
  },
}));
const ErrorPage = ({ error }) => {
  const reload = () => {
    window.location.reload();
  };
  const classes = useStyles();
  return (
    <div className={classes.alignItemsAndJustifyContent}>
      <h2 className="mt-10">Oops! Looks like something went wrong.</h2>
      <Button
        variant="contained"
        className={classes.btn}
        size="large"
        onClick={reload}
      >
        Try Again
      </Button>
    </div>
  );
};

export default ErrorPage;
