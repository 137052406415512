import { useEffect } from "react";

const LogOut = () => {
    useEffect(() => {
        if(sessionStorage.getItem('isCorpUser') === 'true'){
            window.location.href = 'https://login.microsoftonline.com/7b5043c5-ff30-434b-b66e-f8cf457db68a/oauth2/v2.0/logout?client_id='+process.env.REACT_APP_MSAL_APP_ID+'&post_logout_redirect_uri='+process.env.REACT_APP_MSAL_REDIRECT_URI;
        } else {
            window.location.href = '/';
        }
        sessionStorage.clear();
    }, []); 
    return null;
}

export default LogOut