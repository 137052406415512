import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CircularProgress, Divider } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import { Bar, Line } from "react-chartjs-2";
import { chartValue, getDate, getStoreChecker } from "../../utils/utils";
import {
  GET_DEFAULT_LOCATION,
  GET_TREND_SUMMARY,
} from "../../graphql/queries.js";
import ErrorMessage from "../ErrorMessage";
import {
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
} from "../../common/constants";
import "./styles.scss";

export const round = (num) => {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
};

function useMediaQuery(query) {
  const getMatches = (query) => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      if (window.matchMedia(query) && window.matchMedia(query).matches)
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  useEffect(() => {
    const handleChange = () => {
      setMatches(getMatches(query));
    };
    const matchMedia = window.matchMedia(query);
    // Triggered at the first  load and if query changes
    handleChange();
    if (matchMedia && matchMedia.addEventListener)
      matchMedia.addEventListener("change", handleChange);
    return () => {
      if (matchMedia && matchMedia.removeEventListener)
        matchMedia.removeEventListener("change", handleChange);
    };
  }, [query]);

  return matches;
}

const EfficiencyDetailTab = ({ employeeInfo = {}, selectedDateMatrix }) => {
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  const matches = useMediaQuery("(max-width: 767px)");
  const [varToPlanLabel, setVarToPlanLabel] = useState([]);
  const [varToPlanValue, setVarToPlanValue] = useState([]);
  const [overTimeLabel, setOverTimeLabel] = useState([]);
  const [overTimeValue, setOverTimeValue] = useState([]);
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );
  const userEmail = employeeInfo.adUpn;
  const [
    getEfficiencyView,
    { data: { labor: { trend = {} } = {} } = {}, error = {}, loading },
  ] = useLazyQuery(GET_TREND_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      selectedDateMatrix
    ) {
      setVarToPlanLabel([]);
      setVarToPlanValue([]);
      setOverTimeLabel([]);
      setOverTimeValue([]);
      getEfficiencyView({
        variables: {
          request: {
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            email: userEmail,
            toDate: getDate("YESTERDAY", ""),
            matrixType: selectedDateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    selectedLocation,
    selectedDateMatrix,
    getEfficiencyView,
    setVarToPlanLabel,
    setVarToPlanValue,
    setOverTimeLabel,
    setOverTimeValue,
  ]);

  const { overtimePercentage = {}, varianceToPlanHours = {} } = trend || {};

  useEffect(() => {
    if (trend && Object.keys(trend).length > 0) {
      let keyVarianceToPlanHours = [];
      let valueVarianceToPlanHours = [];
      let keyOvertimePercentage = [];
      let valueOvertimePercentage = [];
      for (let key in varianceToPlanHours.keyList) {
        if (varianceToPlanHours.keyList.hasOwnProperty(key)) {
          keyVarianceToPlanHours.push(varianceToPlanHours.keyList[key].key);
          valueVarianceToPlanHours.push(
            Math.round(varianceToPlanHours.keyList[key].value)
          );
        }
      }

      for (let key in overtimePercentage.keyList) {
        if (overtimePercentage.keyList.hasOwnProperty(key)) {
          keyOvertimePercentage.push(overtimePercentage.keyList[key].key);
          valueOvertimePercentage.push(
            chartValue(overtimePercentage.keyList[key].value)
          );
        }
      }

      setVarToPlanLabel(keyVarianceToPlanHours);
      setVarToPlanValue(valueVarianceToPlanHours);
      setOverTimeLabel(keyOvertimePercentage);
      setOverTimeValue(valueOvertimePercentage);
    }
  }, [
    trend,
    overtimePercentage,
    varianceToPlanHours,
    setVarToPlanLabel,
    setVarToPlanValue,
    setOverTimeLabel,
    setOverTimeValue,
  ]);

  const varToPlanHoursData = {
    labels: varToPlanLabel,
    datasets: [
      {
        axis: "y",
        data: varToPlanValue,
        borderColor: "#3333ff",
        barPercentage: 1,
        categoryPercentage: 0.5,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",

        fill: "start",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 150);
          gradient.addColorStop(0, "#367bff");
          gradient.addColorStop(0.5, "#669cff");
          gradient.addColorStop(1, "#cbddf6");

          return gradient;
        },
      },
    ],
  };

  const lineChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.formattedValue !== null) {
              label += context.formattedValue + " %";
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          callback: function (value) {
            return (Math.round(value * 100) / 100).toFixed(2) + " %";
          },
        },
      },
      x: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  const overtimeData = {
    labels: overTimeLabel,
    datasets: [
      {
        data: overTimeValue,
        borderColor: "#f68023",
        fill: "start",
        pointRadius: 5,
        pointHitRadius: 5,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = matches
            ? ctx.createLinearGradient(0, 175, 0, 0)
            : ctx.createLinearGradient(0, 250, 0, 0);
          gradient.addColorStop(1, "#ffa032");
          gradient.addColorStop("0.8", "#ffa030");
          gradient.addColorStop("0.6", "#ff9f39");
          gradient.addColorStop("0.5", "#ffc699");
          gradient.addColorStop("0.3", "#ffdfc6");
          gradient.addColorStop(0, "#ffffff");
          return gradient;
        },
      },
    ],
  };

  const barChartoptions = {
    indexAxis: "x",
    layout: {
      padding: [0, 10],
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.formattedValue !== null) {
              label += context.formattedValue;
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: (context) => {
            const zeroLine = context.tick.value;
            const barColor = zeroLine === 0 ? "#000" : "#dcdcdc";
            return barColor;
          },
        },
        ticks: {
          autoSkip: false,
          callback: function (value) {
            if (value >= 0) return value;
            else return "(" + Math.abs(value) + ")";
          },
        },
      },
      x: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };
  return (
    <div data-testid="labor-trend" className="efficiency-container">
      {loading && (
        <div className="efficiency-detail-loader">
          <CircularProgress color="primary" size={30} />
        </div>
      )}
      {!loading && (
        <>
          {locationError.message || error.message ? (
            <ErrorMessage
              statusCode={
                locationError?.networkError?.statusCode ||
                error?.networkError?.statusCode
              }
              message={
                locationError?.networkError?.statusCode ||
                error?.networkError?.statusCode
                  ? DATA_FETCH_ERROR
                  : error.message
              }
            />
          ) : null}
          {activeStorechecker === false && selectedStoreId ? (
            <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
          ) : null}

          <div className="efficencySplitScreen">
            <div className="efficencyTopPane">
              <div className="efficencyInnerTopPane">
                <div className="efficencyTitleTopPane">
                  <div className="efficency-title-row-top">
                    Variance to Plan (Hours)
                  </div>
                </div>

                <div className="efficencyContentTopPane">
                  <Bar
                    style={{ border: "1px solid rgba(255, 255, 255)" }}
                    type="bar"
                    width="100%"
                    flexGrow="1"
                    options={barChartoptions}
                    data={varToPlanHoursData}
                    borderWidth={2}
                    pointBorderColor={"#fff"}
                    pointBackgroundColor={"rgba(173, 53, 186, 0.1)"}
                  />
                </div>
              </div>
            </div>
            <Divider className="dividerLine" />
            <div className="efficencyBottomPane">
              <div className="efficencyInnerBottomPane">
                <div className="efficencyTitleBottomPane">
                  <div className="efficency-title-row-bottom">Overtime %</div>
                </div>
                <div className="efficencyContentBottomPane">
                  <Line data={overtimeData} options={lineChartoptions} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
  selectedDateMatrix: state?.salesSummary?.currentDateMatrix?.value,
});

export default connect(mapStateToProps, undefined)(EfficiencyDetailTab);
