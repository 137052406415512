import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client/react";
import { Provider } from "react-redux";
import store from "./store";
import { apolloClient } from "./graphql";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./config/AppInsights";
import PageNavigation from "./components/PageNavigation";
import ErrorPage from "./components/ErrorMessage/ErrorPage";
import { withSnackbar } from "notistack";
import * as serviceWorker from "./serviceWorkerRegistration";
import { Button } from "@material-ui/core";
import "./index.scss";

const App = ({ enqueueSnackbar }) => {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState({});

  useEffect(() => {
    let tempLoc = sessionStorage.getItem("selectedLocation");
    if(!tempLoc || tempLoc === undefined || tempLoc === null){
      sessionStorage.setItem(
        "selectedLocation",
        JSON.stringify({ locationNo: "NA", locationName: "NA" })
      );
    }
    const onServiceWorkerUpdate = (registration) => {
      setWaitingWorker(registration && registration.waiting);
      setNewVersionAvailable(true);
    };

    const updateServiceWorker = () => {
      waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
      setNewVersionAvailable(false);
      window.location.reload();
    };

    const refreshAction = (key) => {
      //render the snackbar button
      return (
        <Fragment>
          <Button
            className="snackbar-button"
            size="small"
            variant="outlined"
            onClick={updateServiceWorker}
          >
            {"Refresh"}
          </Button>
        </Fragment>
      );
    };

    serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
    
    if (newVersionAvailable)
      //show snackbar with refresh button
      enqueueSnackbar("A new version was released !", {
        persist: true,
        variant: "info",
        action: refreshAction,
      });
  });

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={(e) => <ErrorPage error={e} />}
        appInsights={reactPlugin}
      >
        <Provider store={store}>
          <ApolloProvider client={apolloClient}>
            <BrowserRouter>
                <div className="App">
                  <PageNavigation />
                </div>
            </BrowserRouter>
          </ApolloProvider>
        </Provider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};

export default withSnackbar(App);
