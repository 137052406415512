
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_PENDING = "FETCH_USERS_PENDING";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";


export const SEARCH_USER = "SEARCH_USER";
export const SEARCH_USER_PENDING = "SEARCH_USER_PENDING";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILED = "SEARCH_USER_FAILED";


export const SET_LOGIN_USER_INFO = "SET_LOGIN_USER_INFO";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const   SET_CURRENT_MATRIX = "SET_CURRENT_MATRIX";


export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const FETCH_USER_DETAILS_PENDING = "FETCH_USER_DETAILS_PENDING";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILED = "FETCH_USER_DETAILS_FAILED";

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
