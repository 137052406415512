import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import "./styles.scss";
import { useHistory } from "react-router";

const BottomNavigator = ({Label, RightRoute, LeftRoute, DisableLeft = false, DisableRight = false}) => {
  let history = useHistory();
  const navigate = (path) => {
    history.push({
      pathname: path,
    });
  }
  return (
        <div data-testid="bottom-navigator" className="footer-container">
          <IconButton
            data-testid="btnLeft"
            disabled={DisableLeft}
            className="btnLeft"
            color="primary"
            size="medium"
            onClick={(e) => navigate(LeftRoute)}
          >
            <ChevronLeft fontSize="inherit" variant="outlined" />
          </IconButton>
          <p className="text">{Label}</p>
          <IconButton
            data-testid="btnRight"
            disabled={DisableRight}
            className="btnRight"
            color="primary"
            size="medium"
            onClick={(e) => navigate(RightRoute)}
          >
            <ChevronRight fontSize="inherit" variant="outlined" />
          </IconButton>
        </div>
  );
};
BottomNavigator.propTypes = {
  Label: PropTypes.string,
  RightRoute: PropTypes.string,
  LeftRoute: PropTypes.string,
  DisableLeft: PropTypes.bool,
  DisableRight: PropTypes.bool
};

export default BottomNavigator;
