import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ErrorMessage from "../ErrorMessage";
import { DATA_FETCH_ERROR, NO_CONNECTION_ERROR } from "../../common/constants";
import { useLazyQuery } from "@apollo/client";
import AddIcon from "../../images/add_icon.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import RemoveIcon from "../../images/remove_icon.png";
import { SummaryDataItem } from "../SummaryCard";
import { toFixedVal, toTitleCase, getDate } from "../../utils/utils";
import { GET_EXPANDABLE_VIEW } from "../../graphql/queries.js";
import useNetwork from "../UseNetwork";
import "./styles.scss";

const ShowRegionData = ({ region, title }) => {
  const {
    regionName,
    extNetDsctAmt,
    performanceToPlan,
    compPercent,
    districtList,
  } = region;
  const [isRegionExpanded, setRegionExpanded] = useState(
    title === "Region Summary" ? true : false
  );

  const toggleIsRegionExpanded = (e) => {
    e.stopPropagation();
    setRegionExpanded(!isRegionExpanded);
  };

  return (
    <div
      className={
        title !== "Region Summary"
          ? "sales-container expanded-region"
          : "sales-container unset-expanded-region"
      }
    >
      <div className="sales-row-container">
        {title !== "Region Summary" ? (
          <>
            <div onClick={toggleIsRegionExpanded} className="sales-title bold">
              <div className="add-remove-icon" data-testid="add-remove-icon">
                {isRegionExpanded ? <img alt="remove icon" src={RemoveIcon} /> : <img alt="add icon" src={AddIcon} />}
              </div>
              <span>{toTitleCase(regionName)}</span>
            </div>
            <div className="data-row">
              <SummaryDataItem
                itemAmount={Math.round(extNetDsctAmt).toLocaleString("en")}
                itemTitle="Sales $"
              />
              <SummaryDataItem
                itemAmount={toFixedVal(performanceToPlan, "%", "end", 1)}
                itemTitle="% to Plan"
              />
              <SummaryDataItem
                itemAmount={toFixedVal(compPercent, "%", "end", 1)}
                itemTitle="Comp %"
              />
            </div>
            <div className="border-row" />
          </>
        ) : null}
      </div>
      {isRegionExpanded &&
        districtList.map((district) => {
          const { districtName, districtNumber } = district;
          return (
            <ShowDistrictData
              key={`${districtName}-${districtNumber}`}
              district={district}
              title={title}
            />
          );
        })}
    </div>
  );
};
const ShowDistrictData = ({ district, title }) => {
  const {
    districtName,
    extNetDsctAmt,
    performanceToPlan,
    compPercent,
    storeList,
  } = district;
  const [isDistrictExpanded, setDistrictExpanded] = useState(
    title === "District Summary" ? true : false
  );

  const toggleIsDistrictExpanded = (e) => {
    e.stopPropagation();
    setDistrictExpanded(!isDistrictExpanded);
  };

  return (
    <div className="sales-container expanded-district">
      <div className="sales-row-container">
        {title !== "District Summary" ? (
          <>
            <div
              onClick={toggleIsDistrictExpanded}
              className="sales-title bold"
              data-testid="toggleIsDistrictExpanded"
            >
              <div className="add-remove-icon" data-testid="add-remove-icon">
                {isDistrictExpanded ? (
                  <img alt="remove icon" src={RemoveIcon} />
                ) : (
                  <img alt="add icon" src={AddIcon} />
                )}
              </div>
              <span>{toTitleCase(districtName)}</span>
            </div>
            <div className="data-row">
              <SummaryDataItem
                itemAmount={Math.round(extNetDsctAmt).toLocaleString("en")}
                itemTitle="Sales $"
              />
              <SummaryDataItem
                itemAmount={toFixedVal(performanceToPlan, "%", "end", 1)}
                itemTitle="% to Plan"
              />
              <SummaryDataItem
                itemAmount={toFixedVal(compPercent, "%", "end", 1)}
                itemTitle="Comp %"
              />
            </div>
            <div className="border-row" />
          </>
        ) : null}
      </div>
      {isDistrictExpanded &&
        storeList.map((store) => {
          const { storeName, storeNumber } = store;
          return (
            <ShowStoreData key={`${storeName}-${storeNumber}`} store={store} />
          );
        })}
    </div>
  );
};

const ShowStoreData = ({ store }) => {
  const { storeName, extNetDsctAmt, performanceToPlan, compPercent } = store;
  return (
    <div className="sales-container expanded-store">
      <div className="sales-row-container">
        <div className="sales-title">{toTitleCase(storeName)}</div>
        <div className="data-row">
          <SummaryDataItem
            itemAmount={Math.round(extNetDsctAmt).toLocaleString("en")}
            itemTitle="Sales $"
          />
          <SummaryDataItem
            itemAmount={toFixedVal(performanceToPlan, "%", "end", 1)}
            itemTitle="% to Plan"
          />
          <SummaryDataItem
            itemAmount={toFixedVal(compPercent, "%", "end", 1)}
            itemTitle="Comp %"
          />
        </div>
        <div className="border-row" />
      </div>
    </div>
  );
};

const ExpandedSalesSummary = (props) => {
  const employeeInfo = props.employeeInfo;
  const primaryLocationName = props.primaryLocationName;
  const primaryLocationNumber = props.primaryLocationNumber;
  const selectedDateMatrix = props.selectedDateMatrix;
  // eslint-disable-next-line no-unused-vars
  const [isExpanded, setExpanded] = useState(true);
  const userEmail = employeeInfo.adUpn;
  const networkStatus = useNetwork();
  const [
    getExpandableView,
    {
      data: {
        salesSummaryV2: {
          expandableView: { title = "", regionList = [] } = {},
        } = {},
      } = {},
      error = {},
      loading,
    },
  ] = useLazyQuery(GET_EXPANDABLE_VIEW, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });
  useEffect(() => {
    if (
      userEmail &&
      primaryLocationName &&
      primaryLocationName !== "NA" &&
      selectedDateMatrix
    ) {
      getExpandableView({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: primaryLocationNumber,
              primaryLocationName: primaryLocationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: selectedDateMatrix,
          },
        },
      });
    }
  }, [userEmail, primaryLocationName, selectedDateMatrix, getExpandableView, primaryLocationNumber]);

  // eslint-disable-next-line no-unused-vars
  // const toggleIsExpanded = (e) => {
  //   e.stopPropagation();
  //   setExpanded(!isExpanded);
  // };
  const stopNavigationFromSalesSummary = (e) => {
    e.stopPropagation();
  };
  const isDistrictManager = () =>
    title === "District Summary" &&
    regionList.length === 1 &&
    regionList[0].districtList.length > 0;

  if (props.getModalTitle) {
    if (title === "Chain Summary") {
      props.getModalTitle("Chain Sales Summary");
    } else if (title === "Region Summary") {
      props.getModalTitle("Regional Sales Summary");
    } else if (title === "District Summary") {
      props.getModalTitle("District Sales Summary");
    } else {
      props.getModalTitle(title);
    }
  }

  useEffect(() => {
    setTimeout(function Pagecallerfun() {
      window.newrelic.addPageAction("Expanded-Sales-Summary", {
        result: "success",
      });
    }, 1000);
  }, []);

  return (
    <div
      onClick={stopNavigationFromSalesSummary}
      className="expanded-sales-summary"
      data-testid="expanded-sales-container"
    >
      {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
      {loading ? (
        <CircularProgress
          className="circular-progress"
          color="inherit"
          size={30}
        />
      ) : (
        !loading &&
        title && (
          <>
            {isExpanded && !regionList.length && (
              <div style={{ textAlign: "center" }}>No {title} data found.</div>
            )}
            {error.message ? (
              <ErrorMessage
                statusCode={error?.networkError?.statusCode}
                message={DATA_FETCH_ERROR}
              />
            ) : null}
            {isExpanded &&
              isDistrictManager() &&
              regionList[0].districtList.map((district) => {
                const { districtName, districtNumber } = district;
                if (title === "District Summary" && props.getTitleInModal) {
                  props.getTitleInModal(districtName);
                }
                return (
                  <ShowDistrictData
                    key={`${districtName}-${districtNumber}`}
                    district={district}
                    title={title}
                  />
                );
              })}
            {isExpanded &&
              !isDistrictManager() &&
              regionList.map((region) => {
                const { regionName, regionNumber } = region;
                if (title === "Region Summary" && props.getTitleInModal) {
                  props.getTitleInModal(regionName);
                } else if (title === "Chain Summary" && props.getTitleInModal) {
                  props.getTitleInModal("Chain");
                }
                return (
                  <ShowRegionData
                    key={`${regionName}-${regionNumber}`}
                    region={region}
                    title={title}
                  />
                );
              })}
          </>
        )
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
});

export default connect(mapStateToProps, undefined)(ExpandedSalesSummary);
