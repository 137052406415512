import { gql } from "@apollo/client";

export const GET_DEFAULT_LOCATION = gql`
  query salesSummaryV2($request: SalesSummaryRequest!) {
    salesSummaryV2(request: $request) {
      location {
        primaryLocationNumber
        primaryLocationName
        secondaryLocationNumber
        secondaryLocationName
        role
      }
    }
  }
`;
export const GET_SALES_SUMMARY = gql`
  query salesSummaryV2($request: SalesSummaryRequest!) {
    salesSummaryV2(request: $request) {
      salesReport {
        extNetDsctAmt
        plannedAmt
        performanceToPlan
        compPercent
      }
      salesMatrix {
        firstTitle
        secondTitle
        totalSales
        percentageToPlan
        compPercentage
        sales {
          primary
          secondary
          primaryVsPlan
          secondaryVsPlan
          primaryComp
          secondaryComp
          secondaryCompPercentage
          primaryCompPercentage
          primaryVariancePlanAmt
          secondaryVariancePlanAmt
          primaryVarianceCompAmt
          secondaryVarianceCompAmt
        }
        transactions {
          primary
          secondary
          primaryVsLY
          secondaryVsLY
          primaryTxnPct
          secondaryTxnPct
        }
        ads {
          primary
          secondary
          primaryVsLY
          secondaryVsLY
          primaryAdsPct
          secondaryAdsPct
        }
        upt {
          primary
          secondary
          primaryVsLY
          secondaryVsLY
          primaryUptPct
          secondaryUptPct
        }
        aur {
          primary
          secondary
          primaryVsLY
          secondaryVsLY
          primaryAurPct
          secondaryAurPct
        }
      }
    }
  }
`;

export const GET_MERCH_DATA = gql`
  query departmentSummary($request: DepartmentRequest!) {
    departmentSummary(request: $request) {
      departmentSalesReport {
        department {
          deptNumber
          deptName
        }
        units
        comp
        sales
        mix
        compPercent
      }
      departmentTotalSalesReport {
        units
        sales
        compPercent
      }
    }
  }
`;

export const GET_MERCH_SKU_SUMMARY = gql`
  query skuSummary($request: SkuRequest!) {
    skuSummary(request: $request) {
      skuSalesMatrix {
        firstTitle
        secondTitle
        skuSales {
          itemNumber
          itemName
          primaryUnit
          primarySales
          secondaryUnit
          secondarySales
        }
      }
    }
  }
`;

export const GET_MERCH_CLASS_SUMMARY = gql`
  query classLevelSummary($request: ClassRequest!) {
    classLevelSummary(request: $request) {
      classLevelClasses {
        className
        classNo
      }
    }
  }
`;

export const GET_MERCH_CLASS_METRICS = gql`
  query classLevelSummary($request: ClassRequest!) {
    classLevelSummary(request: $request) {
      classLevelReport {
        classLevelClasses {
          className
          classNo
        }
        units
        compPercent
        sales
      }
    }
  }
`;

export const GET_MERCH_CLASS_SKU_METRICS = gql`
  query classLevelSummary($request: ClassRequest!) {
    classLevelSummary(request: $request) {
      classSkuMatrix {
        firstTitle
        secondTitle
        classSales {
          itemNumber
          itemName
          primaryUnit
          primarySales
          secondaryUnit
          secondarySales
        }
      }
    }
  }
`;

export const GET_EXPANDABLE_VIEW = gql`
  query salesSummaryV2($request: SalesSummaryRequest!) {
    salesSummaryV2(request: $request) {
      expandableView {
        title
        regionList {
          regionName
          regionNumber
          extNetDsctAmt
          compPercent
          performanceToPlan
          districtList {
            districtName
            districtNumber
            extNetDsctAmt
            compPercent
            performanceToPlan
            storeList {
              storeName
              storeNumber
              extNetDsctAmt
              compPercent
              performanceToPlan
            }
          }
        }
      }
    }
  }
`;

export const GET_LABOR_SUMMARY = gql`
  query Labor($request: LaborRequest!) {
    labor(request: $request) {
      labor {
        firstTitle
        secondTitle
        laborDetails {
          primaryActualPayrollHrs
          primaryPayrollPlan
          primaryAdjustedPayrollPlan
          primaryInitialHoursPlan
          primaryAdjustedHours
          primaryELHours
          primarySetHours
          primaryEfficiency
          primaryOvertime
          primaryOvertimePercentage
          secondaryActualPayrollHrs
          secondaryPayrollPlan
          secondaryAdjustedPayrollPlan
          secondaryInitialHoursPlan
          secondaryAdjustedHours
          secondaryELHours
          secondarySetHours
          secondaryEfficiency
          secondaryOvertime
          secondaryOvertimePercentage
          primaryVarianceToPlanHours
          secondaryVarianceToPlanHours
        }
      }
    }
  }
`;

export const GET_LABOR_EXPANDED_VIEW = gql`
  query Labor($request: LaborRequest!) {
    labor(request: $request) {
      laborExpandableView {
        title
        regionList {
          regionName
          regionNumber
          actualPayrollHrs
          efficiency
          overtimePercentage
          adjustedPayrollPlan
          districtList {
            districtName
            districtNumber
            actualPayrollHrs
            efficiency
            overtimePercentage
            adjustedPayrollPlan
            storeList {
              storeName
              storeNumber
              actualPayrollHrs
              efficiency
              overtimePercentage
              adjustedPayrollPlan
            }
          }
        }
      }
    }
  }
`;

export const GET_TREND_SUMMARY = gql`
  query Labor($request: LaborRequest!) {
    labor(request: $request) {
      trend {
        overtimePercentage {
          keyList {
            key
            value
          }
        }
        varianceToPlanHours {
          keyList {
            key
            value
          }
        }
      }
    }
  }
`;

export const GET_LOCATION_LIST = gql`
  query salesSummaryV2($request: SalesSummaryRequest!) {
    salesSummaryV2(request: $request) {
      listOfLocations {
        locationName
        locationNo
      }
    }
  }
`;

export const GET_SALES_TREND = gql`
  query salesSummaryV2($request: SalesSummaryRequest!) {
    salesSummaryV2(request: $request) {
      salesTrend {
        varianceToPlanAmount {
          keyList {
            key
            value
          }
        }
        unitsPerTransaction {
          keyList {
            key
            value
          }
        }
        averageDollarSale {
          keyList {
            key
            value
          }
        }
      }
    }
  }
`;

export const GET_INVENTORY = gql`
  query inventoryControl($request: InventoryControlRequest!) {
    inventoryControl(request: $request) {
      inventoryControl {
        replace {
          unitQuantity
        }
        damages {
          unitQuantity
        }
        markedOutOfStock {
          unitQuantity
        }
        knownTheft {
          unitQuantity
        }
        candy {
          unitQuantity
        }

        shipment {
          unitQuantity
        }
      }
    }
  }
`;

export const GET_INVENTORY_EXPANDABLE_VIEW = gql`
  query inventoryControl($request: InventoryControlRequest!) {
    inventoryControl(request: $request) {
      inventoryControlExpandableView {
        title
        chain {
          replaceUnitQuantity
          damagesUnitQuantity
          mosUnitQuantity
          knownTheftUnitQuantity
          shipmentUnitQuantity
          candyUnitQuantity
        }
        regionList {
          regionName
          regionNumber
          replaceUnitQuantity
          damagesUnitQuantity
          mosUnitQuantity
          knownTheftUnitQuantity
          shipmentUnitQuantity
          candyUnitQuantity
          districtList {
            districtName
            districtNumber
            replaceUnitQuantity
            damagesUnitQuantity
            mosUnitQuantity
            knownTheftUnitQuantity
            candyUnitQuantity
            shipmentUnitQuantity
            storeList {
              storeName
              storeNumber
              replaceUnitQuantity
              damagesUnitQuantity
              mosUnitQuantity
              knownTheftUnitQuantity
              shipmentUnitQuantity
              candyUnitQuantity
            }
          }
        }
      }
    }
  }
`;

export const GET_INVENTORY_CONTROL_DRIVER_TAB_DETAIL = gql`
  query inventoryControl($request: InventoryControlRequest!) {
    inventoryControl(request: $request) {
      invControlDrivers {
        damages {
          unitQuantity
          itemCount
          adjustmentsAmount
        }
        markedOutOfStock {
          unitQuantity
          itemCount
          adjustmentsAmount
        }
        total {
          unitQuantity
          itemCount
          adjustmentsAmount
        }
        candy {
          unitQuantity
          itemCount
          adjustmentsAmount
        }
        shipment {
          unitQuantity
          itemCount
          adjustmentsAmount
        }
        replace {
          unitQuantity
          itemCount
          adjustmentsAmount
        }
        knownTheft {
          unitQuantity
          itemCount
          adjustmentsAmount
        }
      }
    }
  }
`;

export const GET_INVENTORY_CONTROL_MERCH = gql`
  query inventoryControlSkuSummary($request: InventoryControlSkuRequest!) {
    inventoryControlSkuSummary(request: $request) {
      inventoryControlZeroSellingSkuReport {
        itemNumber
        itemName
        salesUnitCount
        threeWeekSalesUnitCount
        netOHInvUnitCount
      }
    }
  }
`;

export const GET_INVENTORY_CONTROL_SKU_SUMMARY = gql`
  query inventoryControlSkuSummary($request: InventoryControlSkuRequest!) {
    inventoryControlSkuSummary(request: $request) {
      inventoryControlSkuSalesReport {
        itemNumber
        itemName
        unitQuantity
        adjustmentAmount
      }
    }
  }
`;

export const GET_INVENTORY_CONTROL_DEPARTMENT_SUMMARY = gql`
  query inventoryControlDepartmentSummary(
    $request: InventoryControlDepartmentRequest!
  ) {
    inventoryControlDepartmentSummary(request: $request) {
      inventoryControlDepartmentTotalReport {
        unitQuantity
        itemCount
        adjustmentAmount
      }
    }
  }
`;
export const GET_SERVICES_EXPANDED_SUMMARY = gql`
  query services($request: ServicesRequest!) {
    services(request: $request) {
      servicesExpandableView {
        title
        chain {
          balloonTotalSalesUnits
          balloonSalesAmount
          earPiercingServicesUnits
          earPiercingSalesAmount
        }
        regionList {
          regionName
          regionNumber
          balloonTotalSalesUnits
          balloonSalesAmount
          earPiercingServicesUnits
          earPiercingSalesAmount
          districtList {
            districtName
            districtNumber
            balloonTotalSalesUnits
            balloonSalesAmount
            earPiercingServicesUnits
            earPiercingSalesAmount
            storeList {
              storeName
              storeNumber
              balloonTotalSalesUnits
              balloonSalesAmount
              earPiercingServicesUnits
              earPiercingSalesAmount
            }
          }
        }
      }
    }
  }
`;

export const GET_SERVICES_EXPANDED_TOP_LEVEL_DATA = gql`
  query services($request: ServicesRequest!) {
    services(request: $request) {
      servicesExpandableView {
        title
        chain {
          balloonTotalSalesUnits
          balloonSalesAmount
          earPiercingServicesUnits
          earPiercingSalesAmount
        }
        regionList {
          balloonTotalSalesUnits
          balloonSalesAmount
          earPiercingServicesUnits
          earPiercingSalesAmount
          districtList {
            balloonTotalSalesUnits
            balloonSalesAmount
            earPiercingServicesUnits
            earPiercingSalesAmount
            storeList {
              balloonTotalSalesUnits
              balloonSalesAmount
              earPiercingServicesUnits
              earPiercingSalesAmount
            }
          }
        }
      }
    }
  }
`;

export const GET_SERVICES_SUMMARY_DRIVERS_DETAILS_SUMMARY = gql`
  query services($request: ServicesRequest!) {
    services(request: $request) {
      services {
        balloon {
          totalSalesAmount
          totalSalesUnits
          serviceUnits
          totalTransactions
          servicesSalesAmount
          aftercareTransactionPercentage
          ads
        }
        earPiercing {
          totalSalesAmount
          totalSalesUnits
          serviceUnits
          totalTransactions
          servicesSalesAmount
          aftercareTransactionPercentage
          ads
        }
      }
    }
  }
`;

export const GET_SERVICES_MERCH_SKU_DETAILS = gql`
  query services($request: ServicesSkuRequest!) {
    servicesSkuSummary(request: $request) {
      servicesSkuSalesReport {
        itemNumber
        itemName
        salesAmount
        totalSalesUnits
      }
    }
  }
`;
export const GET_SERVICES_TREND = gql`
  query services($request: ServicesRequest!) {
    services(request: $request) {
      servicesTrend {
        balloons {
          keyList {
            key
            value
          }
        }
        earPiercing {
          keyList {
            key
            value
          }
        }
      }
    }
  }
`;

export const GET_OMNI_CHANNEL_SUMMARY = gql`
  query omni($request: OmniRequest!) {
    omni(request: $request) {
      omni {
        bopis {
          fulfilledOrdersCnt
          fillRate
          ordersPlacedOnline
          totalSales
          totalSalesUnits
          avgPickTime
          abandoned
          custPickupPercent
          avgPickUpTime
          picked
          awaitingPicking
          cancelled
          pickedByCust
        }
        sfs {
          fulfilledOrdersCnt
          fillRate
          ordersPlacedOnline
          totalSales
          totalSalesUnits
          ordersShipped
          avgCompletionTime
        }
      }
    }
  }
`;

export const GET_OMNI_CHANNEL_EXPANDED_TOP_LEVEL_DATA = gql`
  query omni($request: OmniRequest!) {
    omni(request: $request) {
      omniExpandableView {
        title
        chain {
          bopisFulfilledOrdersCount
          bopisUnitsFillRate
          sfsFulfilledOrdersCount
          sfsUnitsFillRate
        }
        regionList {
          regionName
          regionNumber
          bopisFulfilledOrdersCount
          bopisUnitsFillRate
          sfsFulfilledOrdersCount
          sfsUnitsFillRate
          districtList {
            districtName
            districtNumber
            bopisFulfilledOrdersCount
            bopisUnitsFillRate
            sfsFulfilledOrdersCount
            sfsUnitsFillRate
            storeList {
              storeName
              storeNumber
              bopisFulfilledOrdersCount
              bopisUnitsFillRate
              sfsFulfilledOrdersCount
              sfsUnitsFillRate
            }
          }
        }
      }
    }
  }
`;

export const GET_OMNICHANNEL_EXPANDED_SUMMARY = gql`
  query omni($request: OmniRequest!) {
    omni(request: $request) {
      omniExpandableView {
        title
        chain {
          bopisFulfilledOrdersCount
          bopisUnitsFillRate
          sfsFulfilledOrdersCount
          sfsUnitsFillRate
        }
        regionList {
          regionName
          regionNumber
          bopisFulfilledOrdersCount
          bopisUnitsFillRate
          sfsFulfilledOrdersCount
          sfsUnitsFillRate
          districtList {
            districtName
            districtNumber
            bopisFulfilledOrdersCount
            bopisUnitsFillRate
            sfsFulfilledOrdersCount
            sfsUnitsFillRate
            storeList {
              storeName
              storeNumber
              bopisFulfilledOrdersCount
              bopisUnitsFillRate
              sfsFulfilledOrdersCount
              sfsUnitsFillRate
            }
          }
        }
      }
    }
  }
`;
