import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { getDate, toFixedVal, getStoreChecker, roundValue } from "../../utils/utils";
import {
  GET_MERCH_SKU_SUMMARY,
  GET_DEFAULT_LOCATION,
} from "../../graphql/queries";
import {
  DATA_FETCH_ERROR,
  ASCENDING,
  DESCENDING,
  ONLOAN_INVALID_STOREID_ERROR,
  NO_CONNECTION_ERROR,
} from "../../common/constants";
import ErrorMessage from "../ErrorMessage";
import MerchSkuRow from "./MerchSkuRow";
import SortableMetrics from "./SortableMetrics";
import useNetwork from "../UseNetwork";

const MerchTotalRow = ({
  compPercent,
  sales,
  units,
  isSelectedStoreTotal,
  employeeInfo = {},
  dateMatrix,
}) => {
  const [skuSortOptions, setSkuSortOptions] = React.useState([]);
  const [sortBy, setSortBy] = React.useState("primarySales");
  const [sortType, setSortType] = React.useState("desc");
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  const userEmail = employeeInfo.adUpn;
  let selectedStoreId = localStorage.getItem("storeId");
  const networkStatus = useNetwork();
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const [getDefaultLocation, { error: locationError = {} }] = useLazyQuery(
    GET_DEFAULT_LOCATION,
    {
      context: {
        headers: {
          Authorization: localStorage.getItem("jwtToken"),
        },
      },
      onCompleted: (data) => {
        let { salesSummaryV2 } = data;
        if (selectedLocation.locationName === "NA") {
          setSelectedLocation({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          });
          sessionStorage.setItem(
            "selectedLocation",
            JSON.stringify({
              locationNo: salesSummaryV2?.location?.primaryLocationNumber,
              locationName: salesSummaryV2?.location?.primaryLocationName,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const [
    getMerchSkuSummary,
    { data: { skuSummary: { skuSalesMatrix } = {} } = {}, error = {}, loading },
  ] = useLazyQuery(GET_MERCH_SKU_SUMMARY, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      selectedLocation &&
      selectedLocation?.locationName !== "NA" &&
      dateMatrix &&
      isSelectedStoreTotal
    ) {
      getMerchSkuSummary({
        variables: {
          request: {
            email: userEmail,
            location: {
              primaryLocationNumber: selectedLocation?.locationNo,
              primaryLocationName: selectedLocation?.locationName,
            },
            toDate: getDate("YESTERDAY", ""),
            matrixType: dateMatrix,
            pageableSort: {
              sort: sortType,
              orderBy: sortBy,
            },
          },
        },
      });
    }
  }, [
    userEmail,
    selectedLocation,
    dateMatrix,
    getMerchSkuSummary,
    isSelectedStoreTotal,
    sortType,
    sortBy,
  ]);

  const {
    firstTitle = undefined,
    secondTitle = undefined,
    skuSales = [],
  } = skuSalesMatrix || {};

  useEffect(() => {
    if (skuSalesMatrix) {
      let skuSortOptionJson = [
        {
          label: `Sales $`,
          isChecked: true,
          value: "primarySales",
        },
        {
          label: `Units`,
          value: "primaryUnits",
          isChecked: false,
        },
        {
          label: `${secondTitle} Sales $`,
          value: "secondarySales",
          isChecked: false,
        },
        {
          label: `${secondTitle} Units`,
          value: "secondaryUnits",
          isChecked: false,
        },
        {
          label: ASCENDING,
          value: "asc",
          isChecked: false,
        },
        {
          label: DESCENDING,
          value: "desc",
          isChecked: true,
        },
      ];
      setSkuSortOptions(skuSortOptionJson);
    }
  }, [skuSalesMatrix, firstTitle, secondTitle, setSkuSortOptions]);

  const onApplyClickHandler = (selectedOptions) => {
    if (selectedOptions[0].isChecked) {
      setSortBy(selectedOptions[0].value);
    }
    if (selectedOptions[1].isChecked) {
      setSortType(selectedOptions[1].value);
    }
  };

  const updateMenuItems = (updateMenuItems) => {
    setSkuSortOptions(updateMenuItems);
  };

  return (
    <>
      {isSelectedStoreTotal && (
        <div className="merch-store-total-row" data-testid="merch-store-total-row" >
          <div className="flex row">
            <div className="merch-title">Store Total</div>
          </div>
          <div className="flex row">
            <div className="b-1-3 merch-sales-container">
              <div className="merch-sales-value">{roundValue(sales, "$")}</div>
              <div className="merch-sales-label">Sales $</div>
            </div>
            <div className="b-1-3 merch-sales-container">
              <div className="merch-sales-value">{roundValue(units)}</div>
              <div className="merch-sales-label">Units</div>
            </div>
            <div className="b-1-3 merch-sales-container">
              <div className="merch-sales-value">
                {toFixedVal(compPercent, "%", "end", 1)}
              </div>
              <div className="merch-sales-label">Comp%</div>
            </div>
          </div>
          <div className="border-row top-border-row" />
        </div>
      )}
      {isSelectedStoreTotal && (
        <div className="sku-table-container">
          {loading && isSelectedStoreTotal && (
            <div className="merch-detail-loader">
              <CircularProgress color="primary" size={30} />
            </div>
          )}
          {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
          {!loading && (
            <>
              {error.message ? (
                <ErrorMessage
                  statusCode={
                    error?.networkError?.statusCode ||
                    locationError?.networkError?.statusCode
                  }
                  message={DATA_FETCH_ERROR}
                />
              ) : null}
              {activeStorechecker === false && selectedStoreId ? (
                <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
              ) : null}

              {isSelectedStoreTotal &&
                skuSalesMatrix &&
                skuSales?.length > 0 &&
                skuSortOptions.length > 0 && (
                  <>
                    <div className="merch-sticky-container">
                      <SortableMetrics
                        onApplyClickHandler={onApplyClickHandler}
                        sortOptions={skuSortOptions}
                        updateMenuItems={updateMenuItems}
                      />
                      <div className="merch-sku-label-container">
                        <div className="merch-sku-label">Sales $</div>
                        <div className="merch-sku-label">Units</div>
                        <div className="merch-sku-label">
                          {secondTitle} Sales $
                        </div>
                        <div className="merch-sku-label">
                          {secondTitle} Units
                        </div>
                      </div>
                    </div>
                  </>
                )}
              <div className="merch-skurow-container">
                {isSelectedStoreTotal &&
                  skuSales?.length > 0 &&
                  skuSales.map((skuSales, index) => {
                    const {
                      itemNumber,
                      primaryUnit,
                      primarySales,
                      secondaryUnit,
                      secondarySales,
                      itemName,
                    } = skuSales;

                    return (
                      <MerchSkuRow
                        key={`${itemNumber}-${index}`}
                        firstTitle={firstTitle}
                        secondTitle={secondTitle}
                        sales={skuSales}
                        itemNumber={itemNumber}
                        primaryUnit={primaryUnit}
                        primarySales={primarySales}
                        secondaryUnit={secondaryUnit}
                        secondarySales={secondarySales}
                        itemName={itemName}
                      />
                    );
                  })}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
  dateMatrix: state.salesSummary.currentDateMatrix.value,
});

export default connect(mapStateToProps, undefined)(MerchTotalRow);
