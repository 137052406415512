import React, { useEffect } from "react";
import AppHeader from "../AppHeader";
import { makeStyles } from "@material-ui/core/styles";
import SelectDropdown from "../SelectDropdown";
import { CircularProgress } from "@material-ui/core";
import {
  getDropdownOptions,
  getTrendDropdownOptions,
  toTitleCase,
  getStoreChecker,
} from "../../utils/utils";
import { useLazyQuery } from "@apollo/client";
import { GET_DEFAULT_LOCATION } from "../../graphql/queries";
import ErrorMessage from "../ErrorMessage";
import {
  DATA_FETCH_ERROR,
  ONLOAN_INVALID_STOREID_ERROR,
  TREND,
  YESTERDAY,
  LCW,
  MTD,
  WTD,
  OMNI_CHANNEL
} from "../../common/constants";
import "./styles.scss";
import BottomNavigator from "../BottomNavigator/BottomNavigator";
import { connect, useSelector } from "react-redux";
import { setCurrentDateMatrix } from "../../actions/actions";
import { OmniTabManager } from "../TabManager";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "1rem",
  },

  dateRow: {
    fontSize: "1.6rem",
    fontWeight: "500",
    fontFamily: "Franklin Gothic Pro ",
    color: "#353535",
    width: "19rem",
    height: "3rem",
    marginBottom: "1rem",
  },
}));

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.6rem",
    fontFamily: "Franklin Gothic Pro ",
    color: state.isSelected ? "#FFFFFF" : "#353535",
    padding: 7,
  }),
};

const OmniChannelDetails = ({ employeeInfo = {}, currentDateMatrix }) => {
  const classes = useStyles();
  const [options, setOptions] = React.useState([]);
  const [activeTab, setTab] = React.useState("");
  const currentActiveTab = useSelector(
    (state) => state.currentActiveTab.currentActiveTab
  );
  useEffect(() => {
    setTab(currentActiveTab);
  }, [activeTab, currentActiveTab]);

  const [selectedDate, setSelectedDates] = React.useState(null);
  const userEmail = employeeInfo.adUpn;
  const [selectedLocation, setSelectedLocation] = React.useState(
    JSON.parse(sessionStorage.getItem("selectedLocation"))
  );
  let selectedStoreId = localStorage.getItem("storeId");
  const activeStorechecker = getStoreChecker(selectedStoreId);
  const [
    getDefaultLocation,
    { error: locationError = {}, loading: locationLoading },
  ] = useLazyQuery(GET_DEFAULT_LOCATION, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
    onCompleted: (data) => {
      let { salesSummaryV2 } = data;
      if (selectedLocation.locationName === "NA") {
        setSelectedLocation({
          locationNo: salesSummaryV2?.location?.primaryLocationNumber,
          locationName: salesSummaryV2?.location?.primaryLocationName,
        });
        sessionStorage.setItem(
          "selectedLocation",
          JSON.stringify({
            locationNo: salesSummaryV2?.location?.primaryLocationNumber,
            locationName: salesSummaryV2?.location?.primaryLocationName,
          })
        );
      }
    },
  });

  useEffect(() => {
    let options =
      activeTab === TREND ? getTrendDropdownOptions() : getDropdownOptions();
    setOptions(options);

    let dropdownVal = "";
    const selectedDate = localStorage.getItem("selectedDate");

    if (
      selectedDate === YESTERDAY ||
      selectedDate === LCW ||
      selectedDate === WTD
    ) {
      dropdownVal = activeTab === TREND ? MTD : selectedDate;
    } else {
      dropdownVal =
        activeTab === TREND && selectedDate === MTD
          ? MTD
          : selectedDate || (activeTab === TREND ? MTD : YESTERDAY);
    }

    const defaultValue = options.find((obj) => obj.value === dropdownVal);
    setSelectedDates(defaultValue);
    currentDateMatrix(defaultValue);
  }, [setOptions, setSelectedDates, activeTab, currentDateMatrix]);

  useEffect(() => {
    if (userEmail && (activeStorechecker === true || selectedStoreId === "")) {
      getDefaultLocation({
        variables: { request: { email: userEmail, storeId: selectedStoreId } },
      });
    }
  }, [userEmail, getDefaultLocation, selectedStoreId, activeStorechecker]);

  const handleDateChange = (e) => {
    setSelectedDates(e);
    currentDateMatrix(e);
    localStorage.setItem("selectedDate", e.value);
  };

  const primarylocation = selectedLocation?.locationName
  const statelocation = primarylocation.split(/\s*[\s,]\s*/).pop();
      let withoutstatelocation = "";
      (primarylocation !== 'NA') ?
      statelocation.length === 2
        ? (withoutstatelocation = primarylocation?.slice(
            0,
            primarylocation.lastIndexOf(",")
          ))
        : (withoutstatelocation = primarylocation)
        : withoutstatelocation = 'NA';

  return (
    <>
      <AppHeader
        headerContent={
          <div className={classes.filterContainer}>
            {locationLoading ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              <>
                <div className="locationRow">
                  {toTitleCase(withoutstatelocation ?? withoutstatelocation)}
                  {(primarylocation !== 'NA') && (statelocation.length === 2) ? <span>, {statelocation}</span> : ""}
                </div>
              </>
            )}
            {options.length > 0 ? (
              <div className="blue-dropdown">
                <SelectDropdown
                  className={classes.dateRow}
                  styles={customStyles}
                  onChange={handleDateChange}
                  options={options}
                  value={selectedDate}
                  isSearchable={false}
                />
              </div>
            ) : (
              <CircularProgress color="inherit" size={30} />
            )}
          </div>
        }
      >
        {locationError.message ? (
          <ErrorMessage
            statusCode={locationError?.networkError?.statusCode}
            message={DATA_FETCH_ERROR}
          />
        ) : null}
        {activeStorechecker === false && selectedStoreId ? (
          <ErrorMessage message={ONLOAN_INVALID_STOREID_ERROR} />
        ) : null}
        <OmniTabManager />
        <BottomNavigator
          Label={OMNI_CHANNEL}
          LeftRoute="/services-details/drivers"
          RightRoute="/labor-details/labor"
          DisableRight={false}
          DisableLeft={false}
        />
      </AppHeader>
    </>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
});
const mapDispatchToProps = (dispatch) => ({
  currentDateMatrix: (selectedDateMatrix) =>
    dispatch(setCurrentDateMatrix(selectedDateMatrix)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OmniChannelDetails);
