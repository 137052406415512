import React, { useState } from "react";
import Check from "@material-ui/icons/Check";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ASCENDING, DESCENDING } from "../../common/constants";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
const SortableMetrics = ({
  sortOptions,
  onApplyClickHandler,
  updateMenuItems,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState(sortOptions || []);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMenuItem = (selectedMenuItem, index) => () => {
    const updatedMenuItems = menuItems.map((mi) => {
      if (mi.label !== selectedMenuItem.label) {
        if (
          ([ASCENDING, DESCENDING].includes(selectedMenuItem.label) &&
            ![ASCENDING, DESCENDING].includes(mi.label)) ||
          (![ASCENDING, DESCENDING].includes(selectedMenuItem.label) &&
            [ASCENDING, DESCENDING].includes(mi.label))
        ) {
          return mi;
        }
        return { ...mi, isChecked: false };
      }
      return { ...mi, isChecked: true };
    });
    setMenuItems(updatedMenuItems);
  };

  const applyFilter = () => {
    handleClose();
    const checkedMenuItems = menuItems.filter((mi) => mi.isChecked);
    updateMenuItems(menuItems);
    onApplyClickHandler(checkedMenuItems);
  };

  return (
    <div data-testid="sortable-metrics" className="sort-container">
      {/* <div className="sort-container"> */}
      {/* <div className="sort-box" id="sort-button"
        aria-controls={open ? 'sort-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
    <span>Sort</span>
    <svg className="sort-arrow" width="10" height="10" viewBox="0 0 10 10">
      <path d="M1 3L5 7L9 3" stroke="#007AFF" strokeWidth="1.5" fill="none" />
    </svg>
  </div> */}
      {/* </div> */}
      <div className="sort-box">
        <Button
          id="sort-button"
          aria-controls={open ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          //  variant="contained"
          className="sort-button"
        >
          Sort
          <KeyboardArrowDown
            color="primary"
            fontSize="large"
            variant="filled"
          />
        </Button>

        <Menu
          id="sort-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "sort-button",
          }}
          className="sort-menu-list-wrapper"
        >
          <MenuItem disabled className="menu-item sort-by-header">
            Sort By
          </MenuItem>
          {menuItems.map((mi, index) => (
            <MenuItem
              key={mi.label}
              className={`menu-item ${
                mi.label === ASCENDING ? "ascending-menu-item" : ""
              }`}
              onClick={toggleMenuItem(mi, index)}
            >
              <span>{mi.label}</span>
              {mi.isChecked && <Check color="primary" fontSize="medium" />}
            </MenuItem>
          ))}
          <MenuItem onClick={applyFilter} className="menu-item apply-button">
            Apply
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default SortableMetrics;
