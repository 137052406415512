import React from "react";

const LaborDataItem = ({ itemAmount, itemTitle }) => {
  return (
    <div className="labor-data-item-container">
      <div className="labor-data-title">{itemTitle}</div>
      <div className="labor-data">{itemAmount}</div>
    </div>
  );
};

export default LaborDataItem;
