import React from "react";

const DataRow = ({ title, primaryValue, isFirstRow }) => {
  return (
    <div className="flex row">
      <div className={`data-text-first ${isFirstRow ? "first-column" : "value-row first"}`}>
        {title}
      </div>
      <div className={`data-text-second ${isFirstRow ? "first-row" : "value-row"}`}>
         {primaryValue}
      </div>
    </div>
  );
};

export default DataRow;
