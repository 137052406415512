import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiDialogContent from "@material-ui/core/DialogContent";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "./styles.scss";
import { useLazyQuery } from "@apollo/client";
import { GET_LOCATION_LIST } from "../../graphql/queries";
import { connect } from "react-redux";
import ErrorMessage from "../ErrorMessage";
import { DATA_FETCH_ERROR } from "../../common/constants";
import Typography from "@material-ui/core/Typography";
import SelectDropdown from "../SelectDropdown";
import {
  getDate,
  getRoleDropDownOptions,
  toTitleCase,
} from "../../utils/utils";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  placeholder: {
    "& input::placeholder": {
      fontSize: "15px",
    },
    width: "100%",
  },
  placeholderPhone: {
    "& input::placeholder": {
      fontSize: "15px",
      fontWeight: "650",
      fontstyle: "bold",
    },
    width: "80%",
  },
  labelFontPhone: {
    fontSize: 12,
    inlineSize: "180px",
    overflow: "hidden",
  },
  labelFont: {
    fontSize: 8,
    inlineSize: "150px",
    overflow: "hidden",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

function StyledRadio(props) {
  const classes = useStyles();
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function useMediaQuery(query) {
  const getMatches = (query) => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      if (window.matchMedia(query) && window.matchMedia(query).matches)
        return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  useEffect(() => {
    const handleChange = () => {
      setMatches(getMatches(query));
    };
    const matchMedia = window.matchMedia(query);
    // Triggered at the first  load and if query changes
    handleChange();
    if (matchMedia && matchMedia.addEventListener)
      matchMedia.addEventListener("change", handleChange);
    return () => {
      if (matchMedia && matchMedia.removeEventListener)
        matchMedia.removeEventListener("change", handleChange);
    };
  }, [query]);

  return matches;
}
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "1.5rem",
    color: state.isSelected ? "#FFFFFF" : "#353535",
    padding: 7,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#0662ed",
  }),
};

export const UserImpersonation = ({
  employeeInfo = {},
  impOpen,
  handleImpClose,
}) => {
  const classes = useStyles();
  const selectedLocation = JSON.parse(
    sessionStorage.getItem("selectedLocation")
  );
  const matches = useMediaQuery("(min-width: 600px)");
  const [tempLocationList, setTempLocationList] = useState([]);
  const [value, setValue] = useState("");
  const [isOpen, setIsOpen] = useState(impOpen);
  const [disableSave, setDisableSave] = useState(true);
  const [location, setLocation] = useState("");
  const userEmail = employeeInfo.adUpn;

  const impIconVisibility = localStorage.getItem("impvisibility");
  const staticUserRole = impIconVisibility?.includes("RD")
    ? "RD"
    : impIconVisibility?.includes("DM")
    ? "DM"
    : "SM";
  const [selectedImpRole, setSelectedImpRole] = React.useState("");
  const [
    getListOfLocation,
    {
      data: { salesSummaryV2: { listOfLocations = [] } = {} } = {},
      error: locationError = {},
      loading: locationLoading,
    },
  ] = useLazyQuery(GET_LOCATION_LIST, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  function callListOfLocations(userEmail, impRole) {
    if (userEmail && impRole) {
      getListOfLocation({
        variables: {
          request: {
            email: userEmail,
            toDate: getDate("YESTERDAY", ""),
            impRole: impRole,
          },
        },
      });
    }
  }

  const search = (event) => {
    let text = event.target.value.replace(/[^\w\s]/gi, "");
    setValue(text);
    if (text && text !== null && text !== undefined && text.length > 0) {
      setTempLocationList(
        listOfLocations?.filter((obj) =>
          obj.locationName.includes(text.toUpperCase())
        )
      );
    } else {
      setTempLocationList([]);
      setDisableSave(true);
      setLocation("");
    }
  };

  const handleRadioChange = (event) => {
    setDisableSave(false);
    setLocation(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let newLoc = tempLocationList.filter(
      (obj) => obj.locationName === location
    );
    sessionStorage.setItem("selectedLocation", JSON.stringify(newLoc[0]));
    setIsOpen(false);
    window.location.reload();
  };
  const handleOnRoleChange = (option) => {
    setTempLocationList([]);
    setDisableSave(true);
    setLocation("");
    setValue("");
    setSelectedImpRole(option);
    callListOfLocations(userEmail, option.value);
  };

  function formatLocationNames(locations) {
    return locations.map((location) => {
      const statelocation = location.locationName.split(/\s*[\s,]\s*/).pop();
      let withoutstatelocation = "";
      statelocation.length === 2
        ? (withoutstatelocation = location.locationName?.slice(
            0,
            location.locationName.lastIndexOf(",")
          ))
        : (withoutstatelocation = location.locationName);
      return {
        ...location,
        cityName: `${withoutstatelocation}`,
        // eslint-disable-next-line no-useless-concat
        statName: statelocation.length === 2 ? `, ` + `${statelocation}` : "",
      };
    });
  }
   const callLocationList = formatLocationNames(tempLocationList);

  const customPlaceholder = () => {
    if (!!selectedImpRole && selectedImpRole.value === "SM") {
      return "Search Stores";
    } else if (!!selectedImpRole && selectedImpRole.value === "DM") {
      return "Search Districts";
    } else if (!!selectedImpRole && selectedImpRole.value === "RD") {
      return "Search Regions";
    }
  };
  return (
    <div data-testid="user-impersonation">
      <Dialog
        fullScreen={!matches}
        fullWidth={false}
        height="auto"
        maxWidth="xs"
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        onClose={handleImpClose}
        disableEscapeKeyDown
        className="container"
      >
        <DialogContent>
          {locationError.message ? (
            <ErrorMessage
              statusCode={locationError?.networkError?.statusCode}
              message={DATA_FETCH_ERROR}
            />
          ) : null}
          <div
            data-testid="user-impersonation-container"
            className="user-impersonation-page"
          >
            <Typography component="span" className="title">
              Impersonation
            </Typography>

            <div
              data-testid="user-impersonation-dropdown-container"
              className="dropdown-container"
            >
              <div className="role_title_container">
                <Typography component="span" className="role_title">
                  Role
                </Typography>
              </div>
              <div
                data-testid="user-impersonation-dropdown"
                className="impersonate-dropdown-column"
              >
                <SelectDropdown
                  className="impersonate-dropdown-row"
                  styles={customStyles}
                  onChange={handleOnRoleChange}
                  options={getRoleDropDownOptions(staticUserRole)}
                  value={selectedImpRole}
                  isSearchable={false}
                  placeholder={<i>Select Role</i>}
                />
              </div>
            </div>
            {locationLoading && (
              <div className="location-loader">
                <CircularProgress
                  variant="indeterminate"
                  size={30}
                  value={100}
                  color="primary"
                />
              </div>
            )}
            {!locationLoading &&
              !!selectedImpRole &&
              listOfLocations?.length > 0 && (
                <div
                  data-testid="user-impersonation-searchbox"
                  className="searchBox-container"
                >
                  <div className="location_title_container">
                    <Typography component="span" className="location_title">
                      Location
                    </Typography>
                  </div>
                  <div className="locationSearch-column">
                    <TextField
                      id="outlined-size-small"
                      type="search"
                      variant="outlined"
                      className="search"
                      classes={{
                        root: matches
                          ? classes.placeholder
                          : classes.placeholderPhone,
                      }}
                      value={value}
                      size="small"
                      autoComplete="off"
                      onChange={search}
                      placeholder={customPlaceholder()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ width: "10%" }}
                          >
                            <IconButton>
                              <SearchIcon className="search-icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              )}
            <div
              data-testid="user-impersonation-location-container"
              className="location-list-container"
            >
              <div className="sample" style={{ width: "20%" }}></div>
              <div className="location-list-column">
                {tempLocationList.length > 0 && !!selectedImpRole ? (
                  <div className="location-list">
                    <RadioGroup
                      name="customized-radios"
                      value={location}
                      onChange={handleRadioChange}
                    >
                      {callLocationList?.map(
                        ({ locationName, cityName, statName }) => (
                          <FormControlLabel
                            disabled={
                              locationName === selectedLocation?.locationName
                                ? true
                                : false
                            }
                            key={locationName}
                            className="location-row"
                            value={locationName}
                            control={<StyledRadio />}
                            label={toTitleCase(cityName) + statName}
                          />
                        )
                      )}
                    </RadioGroup>
                  </div>
                ) : (
                  <div className="location-list-blank" />
                )}
              </div>
            </div>
            <div
              data-testid="user-impersonation-button-container"
              className="btn-row"
            >
              <Button
                className="btn MuiButton-textSizeLarge"
                variant="outlined"
                color="primary"
                onClick={handleImpClose}
              >
                Cancel
              </Button>
              <Button
                className="btn MuiButton-textSizeLarge"
                variant="contained"
                color="primary"
                disabled={disableSave}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
});

export default connect(mapStateToProps, undefined)(UserImpersonation);
