import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ErrorMessage from "../ErrorMessage";
import { DATA_FETCH_ERROR, NO_CONNECTION_ERROR } from "../../common/constants";
import { useLazyQuery } from "@apollo/client";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "../../images/add_icon.png";
import RemoveIcon from "../../images/remove_icon.png";
import LaborDataItem from "../LaborCard/LaborDataItem";
import { toFixedVal, toTitleCase, getDate } from "../../utils/utils";
import { GET_LABOR_EXPANDED_VIEW } from "../../graphql/queries.js";
import useNetwork from "../UseNetwork";
import "./styles.scss";

const ShowRegionData = ({ region, title }) => {
  const {
    regionName,
    actualPayrollHrs,
    adjustedPayrollPlan,
    efficiency,
    overtimePercentage,
    districtList,
  } = region;
  const [isRegionExpanded, setRegionExpanded] = useState(
    title === "Region Summary" ? true : false
  );
  const toggleIsRegionExpanded = (e) => {
    e.stopPropagation();
    setRegionExpanded(!isRegionExpanded);
  };

  return (
    <div
      className={
        title !== "Region Summary"
          ? "labor-container expanded-region"
          : "labor-container unset-expanded-region"
      }
    >
      <div className="labor-row-container">
        {title !== "Region Summary" ? (
          <>
            <div
              data-testid="toggleIsRegionExpanded"
              onClick={toggleIsRegionExpanded}
              className="labor-title bold"
            >
              <div className="add-remove-icon" data-testid="add-remove-icon">
                {isRegionExpanded ? (
                  <img alt="remove icon" src={RemoveIcon} />
                ) : (
                  <img alt="add icon" src={AddIcon} />
                )}
              </div>
              <span>{toTitleCase(regionName)}</span>
            </div>
            <div className="data-row">
              <LaborDataItem
                itemAmount={Math.round(actualPayrollHrs).toLocaleString("en")}
                itemTitle="Payroll Hrs"
              />
              <LaborDataItem
                itemAmount={Math.round(adjustedPayrollPlan).toLocaleString(
                  "en"
                )}
                itemTitle="Payroll Plan"
              />
              <LaborDataItem
                itemAmount={toFixedVal(efficiency, "%", "end", 2)}
                itemTitle="Efficiency"
              />
              <LaborDataItem
                itemAmount={toFixedVal(overtimePercentage, "%", "end", 2)}
                itemTitle="OT%"
              />
            </div>
            <div className="border-row" />
          </>
        ) : null}
      </div>
      {isRegionExpanded &&
        districtList.map((district) => {
          const { districtName, districtNumber } = district;
          return (
            <ShowDistrictData
              key={`${districtName}-${districtNumber}`}
              district={district}
              title={title}
            />
          );
        })}
    </div>
  );
};
const ShowDistrictData = ({ district, title }) => {
  const {
    districtName,
    actualPayrollHrs,
    adjustedPayrollPlan,
    efficiency,
    overtimePercentage,
    storeList,
  } = district;
  const [isDistrictExpanded, setDistrictExpanded] = useState(
    title === "District Summary" ? true : false
  );
  const toggleIsDistrictExpanded = (e) => {
    e.stopPropagation();
    setDistrictExpanded(!isDistrictExpanded);
  };

  return (
    <div className="labor-container expanded-district">
      <div className="labor-row-container">
        {title !== "District Summary" ? (
          <>
            <div
              data-testid="toggleIsDistrictExpanded"
              onClick={toggleIsDistrictExpanded}
              className="labor-title bold"
            >
              <div data-testid="add-remove-icon" className="add-remove-icon">
                {isDistrictExpanded ? (
                  <img alt="remove icon" src={RemoveIcon} />
                ) : (
                  <img alt="add icon" src={AddIcon} />
                )}
              </div>
              <span>{toTitleCase(districtName)}</span>
            </div>
            <div className="data-row">
              <LaborDataItem
                itemAmount={Math.round(actualPayrollHrs).toLocaleString("en")}
                itemTitle="Payroll Hrs"
              />
              <LaborDataItem
                itemAmount={Math.round(adjustedPayrollPlan).toLocaleString(
                  "en"
                )}
                itemTitle="Payroll Plan"
              />
              <LaborDataItem
                itemAmount={toFixedVal(efficiency, "%", "end", 2)}
                itemTitle="Efficiency"
              />
              <LaborDataItem
                itemAmount={toFixedVal(overtimePercentage, "%", "end", 2)}
                itemTitle="OT%"
              />
            </div>
            <div className="border-row" />
          </>
        ) : null}
      </div>
      {isDistrictExpanded &&
        storeList.map((store) => {
          const { storeName, storeNumber } = store;
          return (
            <ShowStoreData key={`${storeName}-${storeNumber}`} store={store} />
          );
        })}
    </div>
  );
};

const ShowStoreData = ({ store }) => {
  const {
    storeName,
    actualPayrollHrs,
    adjustedPayrollPlan,
    efficiency,
    overtimePercentage,
  } = store;
  return (
    <div className="labor-container expanded-store">
      <div className="labor-row-container">
        <div className="labor-title">{toTitleCase(storeName)}</div>
        <div className="data-row">
          <LaborDataItem
            itemAmount={Math.round(actualPayrollHrs).toLocaleString("en")}
            itemTitle="Payroll Hrs"
          />
          <LaborDataItem
            itemAmount={Math.round(adjustedPayrollPlan).toLocaleString("en")}
            itemTitle="Payroll Plan"
          />
          <LaborDataItem
            itemAmount={toFixedVal(efficiency, "%", "end", 2)}
            itemTitle="Efficiency"
          />
          <LaborDataItem
            itemAmount={toFixedVal(overtimePercentage, "%", "end", 2)}
            itemTitle="OT%"
          />
        </div>
        <div className="border-row" />
      </div>
    </div>
  );
};

const ExpandedLaborSummary = (props) => {
  const employeeInfo = props.employeeInfo;
  const primaryLocationNumber = props.primaryLocationNumber;
  const primaryLocationName = props.primaryLocationName;
  const selectedDateMatrix = props.selectedDateMatrix;
  // eslint-disable-next-line no-unused-vars
  const [isExpanded, setExpanded] = useState(true);
  const userEmail = employeeInfo.adUpn;
  const networkStatus = useNetwork();
  const [
    getExpandableView,
    {
      data: {
        labor: {
          laborExpandableView: { title = "", regionList = [] } = {},
        } = {},
      } = {},
      error = {},
      loading,
    },
  ] = useLazyQuery(GET_LABOR_EXPANDED_VIEW, {
    context: {
      headers: {
        Authorization: localStorage.getItem("jwtToken"),
      },
    },
  });

  useEffect(() => {
    if (
      userEmail &&
      primaryLocationName &&
      primaryLocationName !== "NA" &&
      selectedDateMatrix
    ) {
      getExpandableView({
        variables: {
          request: {
            email: userEmail,
            toDate: getDate("YESTERDAY", ""),
            location: {
              primaryLocationNumber: primaryLocationNumber,
              primaryLocationName: primaryLocationName,
            },
            matrixType: selectedDateMatrix,
          },
        },
      });
    }
  }, [
    userEmail,
    primaryLocationNumber,
    primaryLocationName,
    selectedDateMatrix,
    getExpandableView,
  ]);

  // eslint-disable-next-line no-unused-vars
  // const toggleIsExpanded = (e) => {
  //   e.stopPropagation();
  //   setExpanded(!isExpanded);
  // };
  const stopNavigationFromSalesSummary = (e) => {
    e.stopPropagation();
  };
  const isDistrictManager = () =>
    title === "District Summary" &&
    regionList.length === 1 &&
    regionList[0].districtList.length > 0;

  if (props.getModalTitle) {
    if (title === "Chain Summary") {
      props.getModalTitle("Chain Labor Summary");
    } else if (title === "Region Summary") {
      props.getModalTitle("Regional Labor Summary");
    } else if (title === "District Summary") {
      props.getModalTitle("District Labor Summary");
    } else {
      props.getModalTitle(title);
    }
  }

  useEffect(() => {
    setTimeout(function Pagecallerfun() {
      window.newrelic.addPageAction("Expanded-Labor-Summary", {
        result: "success",
      });
    }, 1000);
  }, []);

  return (
    <div
      onClick={stopNavigationFromSalesSummary}
      className="expanded-labor-summary"
      data-testid="expanded-labor-container"
    >
      {!networkStatus && <ErrorMessage message={NO_CONNECTION_ERROR} />}
      {loading ? (
        <CircularProgress
          className="circular-progress"
          color="inherit"
          size={30}
        />
      ) : (
        !loading &&
        title && (
          <>
            {isExpanded && !regionList.length && (
              <div style={{ textAlign: "center" }}>No {title} data found.</div>
            )}
            {error.message ? (
              <ErrorMessage
                statusCode={error?.networkError?.statusCode}
                message={DATA_FETCH_ERROR}
              />
            ) : null}
            {isExpanded &&
              isDistrictManager() &&
              regionList[0].districtList.map((district) => {
                const { districtName, districtNumber } = district;
                return (
                  <ShowDistrictData
                    key={`${districtName}-${districtNumber}`}
                    district={district}
                    title={title}
                  />
                );
              })}
            {isExpanded &&
              !isDistrictManager() &&
              regionList.map((region) => {
                const { regionName, regionNumber } = region;
                return (
                  <ShowRegionData
                    key={`${regionName}-${regionNumber}`}
                    region={region}
                    title={title}
                  />
                );
              })}
          </>
        )
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  employeeInfo: state.auth.employeeInfo,
});

export default connect(mapStateToProps, undefined)(ExpandedLaborSummary);
