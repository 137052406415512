import React from "react";

const TotalDataRow = ({
  title,
  unitQuantity,
  itemCount,
  adjustmentsAmount,
  isFirstRow,
}) => {
  return (
    <>
      <div className="flex row">
        <div className="ic-merch-title">{title}</div>
      </div>
      <div className="flex row ic-merch-row-container">
        <div className="b-1-3 ic-merch-container">
          <div className="ic-merch-main-value">{itemCount}</div>
          <div className="ic-merch-Subtitlemain"># of SKUs</div>
        </div>
        <div className="b-1-3 ic-merch-container">
          <div className="ic-merch-main-value">{unitQuantity}</div>
          <div className="ic-merch-Subtitlemain">Units</div>
        </div>
        <div className="b-1-3 ic-merch-container">
          <div className="ic-merch-main-value">{adjustmentsAmount}</div>
          <div className="ic-merch-Subtitlemain">$</div>
        </div>
      </div>
      <div className="bottom-border-row"></div>
    </>
  );
};

export default TotalDataRow;
