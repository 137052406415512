import Select from "react-select";
import "./styles.scss";

const SelectDropdown = ({
  className,
  isSearchable,
  value,
  onChange,
  options,
  styles,
  placeholder,
  ...restProps
}) => {
  return (
    <div data-testid="select-dropdown">
      <Select
        className={className}
        value={value}
        styles={styles}
        isSearchable={isSearchable === undefined ? true : isSearchable}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SelectDropdown;
