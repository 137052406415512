import {
  SET_LOGIN_USER_INFO,
  SET_SELECTED_LOCATION,
  SET_SELECTED_DATE,
  FETCH_USERS,
  SET_CURRENT_MATRIX,
  SET_ACTIVE_TAB
} from "./types";

export const fetchUsers = () => {
  const url = "/users";
  const payload = {
    action: FETCH_USERS,
    method: "GET",
    url,
  };
  return { type: "API_INVOCATION", payload };
};

export const setLoginUserInfo = (payload) => ({
  type: SET_LOGIN_USER_INFO,
  payload,
});

export const setSelectedLocation = (payload) => ({
  type: SET_SELECTED_LOCATION,
  payload,
});

export const setSelectedDate = (payload) => ({
  type: SET_SELECTED_DATE,
  payload,
});
export const setCurrentDateMatrix = (payload) => ({
  type: SET_CURRENT_MATRIX,
  payload,
});

export const setActiveTab = (payload) => ({
  type: SET_ACTIVE_TAB,
  payload,
});