import React from "react";
import PropTypes from "prop-types";
import { toTitleCase, roundValue } from "../../utils/utils";

// eslint-disable-next-line no-unused-vars
const getTitle = (title) => {
  if (title === "District") {
    return "Dist";
  } else if (title === "Region") {
    return "Reg";
  } else {
    return title;
  }
};

const MerchSkuRow = ({
  itemNumber,
  primaryUnit,
  primarySales,
  secondaryUnit,
  secondarySales,
  itemName,
}) => {
  return (
    <>
      <div className="px-5">
        <div className=" merch-row-container px-15  ">
          <div className=" merch-Subtitle">
            {toTitleCase(`${itemName} ${itemNumber}`)}
          </div>
        </div>
        <div className="flex row">
          <div className="b-1-4 merch-sku-container">
            <div className="merch-sku-value">
              {roundValue(primarySales, "$")}
            </div>
          </div>
          <div className="b-1-4 merch-sku-container">
            <div className="merch-sku-value">
              {roundValue(primaryUnit)}
            </div>
          </div>
          <div className="b-1-4 merch-sku-container">
            <div className="merch-sku-value">
              {roundValue(secondarySales, "$")}
            </div>
          </div>
          <div className="b-1-4 merch-sku-container">
            <div className="merch-sku-value">
              {roundValue(secondaryUnit)}
            </div>
          </div>
        </div>
        <div className="sku-border-row" />
      </div>
    </>
  );
};

MerchSkuRow.defaultProps = {
  itemName: "",
  itemNumber: 0,
  primaryUnit: 0,
  primarySales: 0,
  secondaryUnit: 0,
  secondarySales: 0,
};

MerchSkuRow.propTypes = {
  itemName: PropTypes.string,
  itemNumber: PropTypes.any,
  primaryUnit: PropTypes.any,
  primarySales: PropTypes.any,
  secondaryUnit: PropTypes.any,
  secondarySales: PropTypes.any,
};

export default MerchSkuRow;
