import { SET_LOGIN_USER_INFO, SEARCH_USER_SUCCESS, SEARCH_USER_FAILED, SEARCH_USER_PENDING } from "../actions/types";

const initialState = {
  user: {},
  employeeInfo: {},
  isSearchUserError: false,
  isSearchUserLoading: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_USER_INFO: {
      localStorage.setItem("jwtToken", `Bearer ${action.payload.accessToken}`);
      return {
        ...state,
        user: action.payload,
      };
    }
    case SEARCH_USER_PENDING: {
      return {
        ...state,
        isSearchUserLoading: true,
        isSearchUserError: false
      };
    }
    case SEARCH_USER_SUCCESS: {
      return {
        ...state,
        isSearchUserError: false,
        isSearchUserLoading: false,
        employeeInfo: action.payload.data.data,
      };
    }
    case SEARCH_USER_FAILED: {
      return {
        ...state,
        isSearchUserLoading: false,
        isSearchUserError: true
      };
    }
    default:
      return state;
  }
};

export default authReducer;
